<template>
    <div>
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>个人信息</p>
        </div>
        <div class="main">
            <div class="touxiang" @click="toUploadUserAvatar">
                <p>头像</p>
                <img class="img01" :src="myMain.myAvatar">
                <img class="img02" src="../assets/右箭头.png" alt="">
                <input type="file" style="display: none;" accept="image/*" ref="fileInput" @change="onFileSelected">
            </div>
            <hr />
            <div class="qita" @click="toChangeUserName">
                <p class="qitaP1">昵称</p>
                <p class="qitaP2">{{ myMain.myName }}</p>
                <img src="../assets/右箭头.png" alt="">
            </div>
            <hr />
            <div class="qita">
                <p class="qitaP1">微信号</p>
                <p class="qitaP2">{{ myMain.myId }}</p>
                <img src="../assets/右箭头.png" alt="">
            </div>
            <hr />
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            myMain: {
                myId: null,
                myAvatar: null,
                myName: null
            },
            selectedFile: null, //设置一个变量来存储选择的头像文件
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //获取我的账号信息
        getMyMain() {
            let MyuserId = parseInt(localStorage.getItem('userId'), 10);
            //获取信息
            this.$axios.get('/User/getUserInformation/' + MyuserId).then((res) => {
                console.log(res);
                this.myMain.myId = res.data.data[0].userId;
                this.myMain.myName = res.data.data[0].userName;
                this.myMain.myAvatar = res.data.data[0].avatar;
            })
        },
        //跳转到更改昵称界面
        toChangeUserName() {
            this.$router.push("/changeUserName")
        },
        //处理点击“更改头像”按钮的事件
        selectFile() {
            this.$refs.fileInput.click();  // 触发文件选择
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中
        onFileSelected(event) {
            this.selectedFile = event.target.files[0];

            this.changeAvatarByUserId();
        },
        changeAvatarByUserId() {
            if (!this.selectedFile) {
                // 文件未选择，进行处理与提示
                console.log("请选择头像！！！");
                window.alert('请选择头像');
            }
            const params = new FormData;
            params.append("userId", this.myMain.myId);
            params.append("avatarFile", this.selectedFile);
            this.$axios.post("/User/changeAvatarByUserId", params).then((res) => {
                console.log(res);
                if (res.data.status == 1) {
                    this.$message.success("修改成功！");
                    this.getMyMain();
                } else {
                    this.$message.success("修改失败！");
                }
            });
        },
        toUploadUserAvatar(){
            this.$router.push('/uploadUserAvatar');
        }
    },
    created() {
        this.getMyMain();
    }
}
</script>

<style scoped>
.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.main .touxiang {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.main .touxiang p {
    flex: 1;
    font-size: 20px;
    margin-left: 10px;
}

.main .touxiang .img01 {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 10px;
    object-fit: cover;
}

.main .touxiang .img02 {
    width: 20px;
    height: 20px;
}

/* .touxiang+.qita {
    margin-top: 0.1rem;
} */

.qita {
    background-color: white;
    display: flex;
    align-items: center;
}

.qita .qitaP1 {
    font-size: 20px;
    flex: 1;
    margin-left: 10px;
}

.qita .qitaP2 {
    font-size: 20px;
    margin-right: 15px;
}

.qita img {
    width: 20px;
    height: 20px;
}

.qita+.qita {
    margin-top: 0.1rem;
}
</style>