<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>消息</p>
        </div>
        <div class="center">
            <div class="xiaoxi" v-for="r in this.reviewList" @click="toDynamicDetails(r)">
                <div style="flex: 1;">
                    <img class="img1" :src=r.avatar>
                    <p class="p1">{{ r.nickName }}</p>
                    <div style="margin-left: 64px;margin-top: -14px;">
                        <img style="width: 20px;height: 20px;" v-if="r.reviewContent == null" src="../assets/cp喜欢.png">
                    </div>
                    <p class="p2">{{ r.reviewContent }}</p>
                    <p class="p3">{{ r.reviewTime }}</p>
                </div>
                <div>
                    <img v-if="r.imageList != null" class="img2" :src=JSON.parse(r.imageList)[0]>
                    <img v-if="r.imageList == null" class="img3" src="../assets/article_Daxiong.png">
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            reviewList: [], //评论列表
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = localStorage.getItem("userId");
        },
        getReviewList() {
            const params = new URLSearchParams;
            params.append("userId", this.myId);
            this.$axios.post("/Dynamic/getReviewInteraction", params).then((res) => {
                this.reviewList = res.data.data;
                //console.log(this.reviewList);
            })
        },
        //查看动态详情
        toDynamicDetails(r) {
            localStorage.setItem('dynamicId', r.dynamicId);
            localStorage.setItem('DynamicUserId', this.myId);
            this.$router.push('/dynamicDetails');
        },
        //将未读点赞和评论标为已读
        setReadStatus1(){
            const params = new URLSearchParams;
            params.append('userId',this.myId);
            this.$axios.post("/Dynamic/setReadStatus1",params).then((res)=>{
                //console.log(res);
            })
        },
        //将关于我的所有动态回复及点赞标为已读
        aboveMeAllDynamicReviewAndLikeSetRead1(){
            const params = new URLSearchParams;
            params.append('userId',this.myId);
            this.$axios.post("/Dynamic/aboveMeAllDynamicReviewAndLikeSetRead1",params).then((res)=>{
                console.log(res);
            })
        }
    },
    created() {
        this.getId();
    },
    mounted() {
        this.getReviewList();
        this.aboveMeAllDynamicReviewAndLikeSetRead1();
        this.setReadStatus1();
    },
    // beforeRouteLeave(to, from, next) {    //路由守卫钩子，当退出当前路由之前会执行此函数
    //     this.aboveMeAllDynamicReviewAndLikeSetRead1();
    //     this.setReadStatus1();
    //     next();
    // }
}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.center {
    height: calc(100vh - 55px);
    overflow: auto;
}

.xiaoxi {
    background-color: white;
    width: 100vw;
    height: 70px;
    margin-bottom: 1px;
    display: flex;
}

.xiaoxi .img1 {
    width: 50px;
    height: 50px;
    margin-left: 8px;
    margin-top: 2px;
    border-radius: 8px;
    object-fit: cover;
}

.xiaoxi .p1 {
    margin-left: 65px;
    margin-top: -53px;
    color: royalblue;
}

.xiaoxi .p2 {
    margin-left: 65px;
    margin-top: -12px;
    font-size: 15px;
    padding: 0;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.xiaoxi .p3 {
    margin-left: 65px;
    margin-top: -13px;
    font-size: 12px;
    color: gray;
    padding: 0;
}

.xiaoxi .img2 {
    width: 60px;
    height: 60px;
    margin-top: 5px;
    object-fit: cover;
    margin-right: 10px;
}

.xiaoxi .img3 {
    width: 60px;
    height: 60px;
    margin-top: 5px;
    object-fit: contain;
    margin-right: 10px;
}
</style>