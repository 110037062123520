<template>
    <div class="body">
        <div class="part1">
            <img src="../assets/APPLogo.png">
        </div>
        <div class="part2">
            <div class="inputKuang">
                <img src="../assets/账号.png" alt="">
                <span class="fgx">|</span>
                <input v-model="this.form.userId" type="text" placeholder="请输入账号">
            </div>
            <div class="inputKuang">
                <img src="../assets/锁.png" alt="">
                <span class="fgx">|</span>
                <input v-model="this.form.password" type="password" placeholder="请输入密码">
            </div>
            <div class="div1">
                <span class="span1">没有账号？</span><span class="span2" @click="ToEnroll">去注册</span>
                <input v-model="this.rememberMe" type="checkbox"><span class="span3">记住密码</span>
            </div>
        </div>
        <div class="part3">
            <div class="div1" @click="login">
                <p>登 录</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        userId: '',
        password: ''
      },
      rememberMe: true
    };
  },
  methods: {
    //登录
    login() {
      this.$axios.post('/User/login', this.form).then((res) => {
        //console.log(res);
        if (res.data.status == 200) {
          this.$message.success(res.data.message);
          //token保存到sessionStorage中
          window.sessionStorage.setItem("token", res.data.data.token);
          // 登录成功后，保存用户ID到本地
          localStorage.setItem("userId", res.data.data.userId);
          if(this.rememberMe == true){
            localStorage.setItem('password',this.form.password); // 如果勾选了记住密码，则将密码保存在缓存中
          }else{
            localStorage.removeItem('password');
          }
          this.$router.replace('/myView');
        } else {
          this.$message.error("登录失败，请检查账号或密码！");
        }
      })
    },
    //去注册
    ToEnroll() {
      this.$router.push("/newEnroll");
    },
    //获取缓存的账号
    getUserId(){
        this.form.userId = parseInt(localStorage.getItem('userId'),10);
    },
    //获取缓存的密码
    getPassword(){
        this.form.password = localStorage.getItem('password');
    }


  },
  mounted(){
    this.getUserId();
    this.getPassword();
  }
};
</script>


<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.part1 {
    width: 100vw;
    height: 30vh;
    display: flex;
    align-items: end;
    justify-content: center;
}

.part1 img {
    width: 85px;
    height: 85px;
}

.part2 {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.part2 .inputKuang {
    width: 85vw;
    height: 33px;
    background-color: #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
}



.inputKuang img{
    width: 20px;
    height: 20px;
    margin-left: 15px;
}

.inputKuang .fgx{
    margin-left: 6px;
    font-size: 18px;
    color: gray;
    font-weight: 100;
    margin-bottom: 3px;
}

.inputKuang input{
    margin-left: 10px;
    border: none;
    height: 22px;
    background-color: #efefef;
    outline: none;
    flex: 1;
}

.inputKuang + .inputKuang{
    margin-top: 10px;
}
.part2 .div1{
    display: flex;
    width: 85vw;
    margin-left: 10px;
    margin-top: 10px;
    align-items: center;
}
.part2 .div1 .span1{
    font-size: 13px;
    color: gray;
}
.part2 .div1 .span2{
    font-size: 13px;
    color: red;
    flex: 1;
}
.part2 .div1 .span3{
    font-size: 13px;
    color: gray;
    margin-right: 15px;
}
.part3{
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
}
.part3 .div1{
    width: 85vw;
    background-color: dodgerblue;
    height: 35px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.part3 .div1 p{
    color: white;
}
</style>