import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus';
import { ElMessage } from 'element-plus';
import axios from 'axios';
import 'element-plus/dist/index.css';
import {BASE_URL} from './url.js';


document.title = 'Wechat'

//配置请求的根路径
axios.defaults.baseURL = BASE_URL+'/jiuxing';
const app = createApp(App);

//挂载全局对象
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$message = ElMessage;
// app.directive('photo-preview', preview);

app.use(store).use(router).use(ElementPlus).mount("#app");
