<template>
    <div class="body">
        <div class="chat-container">
            <div class="chat-header">
                <img style="width: 25px; height: 25px;" src="../assets/navigate-left.png" @click="goBack">
                <span class="friend-nickname">{{ friendNickname.nickName }}</span>
                <img @click="toChatSetting" style="width: 25px; height: 25px; position: absolute; right: 15px;"
                    src="../assets/更多.png">
            </div>
            <div :class="{ 'chat-messages': !isChatMessages2, 'chat-messages2': isChatMessages2 }">
                <div v-for="message in messages" :key="message.id"
                    :class="['message', message.senderId === myId ? 'sent' : 'received']">
                    <div v-if="message.senderId === friendId" class="avatar-container">
                        <img class="friend-avatar" :src="avatar.friendavatar" alt="Friend Avatar" />
                    </div>
                    <div class="div_xiaoxi">
                        <div :class="{ 'message-bubble': message.content, 'message-bubble-img': message.imagePath }">
                            <div v-if="message.content" class="message-text">{{ message.content }}</div>
                            <div class="message-image" v-if="message.imagePath">
                                <img :src="message.imagePath" alt="Image" @click="getBigImgSrc(message)">
                            </div>
                            <div :class="{ 'videoDiv1': message.senderId == this.myId, 'videoDiv2': message.senderId == this.friendId }"
                                v-if="message.videoPath">
                                <video muted autoplay @click="toBigVideo(message.videoPath)">
                                    <source :src="message.videoPath" type="video/mp4">
                                </video>
                            </div>
                            <div v-if="message.wavPath"
                                :class="{ 'yuYin_div1': message.senderId == this.myId, 'yuYin_div2': message.senderId == this.friendId }">
                                <button @click="playAudio(message.wavPath)">【语音消息】</button>
                            </div>
                        </div>

                    </div>
                    <div v-if="message.senderId === myId" class="avatar-container">
                        <img class="my-avatar" :src="avatar.myavatar" />
                    </div>
                </div>
            </div>
            <!-- 底部消息发送框 -->
            <div :class="{ 'chat-input': !isChatInput2, 'chat-input2': isChatInput2 }">
                <div class="file-input-container">
                    <input type="file" style="display: none;" accept="image/*" ref="ImgFileInput"
                        @change="onFileSelectedIsImg">
                    <input type="file" style="display: none;" accept="video/*" ref="VideoFileInput"
                        @change="onFileSelectedIsVedio">
                    <img @click="toggleMenu" class="plus-icon" style="width: 28px; height: 28px; margin-right: 2px;"
                        src="../assets/加号8.png">
                    <img @click="openCaidan3" class="plus-icon" style="width: 28px; height: 28px; margin-right: 2px;"
                        src="../assets/语音.png" alt="">
                </div>
                <el-input @click="handInput" type="textarea" :rows="1" v-model="newMessage" placeholder="输入消息..."/>
                <button @click="sendMessage">发送</button>
            </div>

            <div :class="{ 'caidan': !isCaidan2, 'caidan2': isCaidan2 }">
                <img @click="selectImgFile" style="height: 40px;" src="../assets/相册.png">
                <img @click="selectVideoFile" style="height: 40px;" src="../assets/视频文件4_2.png" alt="">
                <img @click="toVidio" style="height: 40px;" src="../assets/视频通话.png">
            </div>

            <div :class="{ 'caidan': !isCaidan3, 'caidan3': isCaidan3 }">
                <button @click="handleStart" style="background-color: springgreen;">开始录音<br />{{ recorder &&
                    recorder.duration.toFixed(4) }}</button>
                <button @click="handleStop" style="background-color: red;">停止录音</button>
                <button @click="sendVoice" style="background-color: cornflowerblue;">发送录音</button>
            </div>
        </div>
    </div>

</template>

<script>
import { inject } from 'vue';
import Recorder from 'js-audio-recorder'
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import Stomp from 'stompjs';

export default {
    data() {
        return {
            friendNickname: {
                nickName: ''
            },
            messages: [],  //合并后的消息数组
            newMessage: '',
            avatar: {
                myavatar: '',   //我的头像
                friendavatar: ''  //好友头像
            },
            myId: 0,  //我的账号
            friendId: 0,  //对方账号
            MeToYouMessage: [], //我给对方发的消息
            YouToMeMessage: [], //对方给我发的消息
            selectedFile: null, //设置一个变量来存储选择的文件
            isChatInput2: false,
            isCaidan2: false,
            isChatMessages2: false,
            stompClient: null,
            isScrollAtBottom: true, // 是否滚动到底部
            isCaidan3: false,
            recorder: null,
            touchStartDistance: 0,
            touchMoveDistance: 0,
            scale: 1,
            pressImg: null,
            ScrollPoint: null
        };
    },
    methods: {
        //点击播放语音
        playAudio(wavPath) {
            const audioUrl = wavPath; // 替换为从后端获取的音频文件URL
            const audio = new Audio(audioUrl);
            audio.play();
        },

        goBack() {
            // 处理返回逻辑
            this.$router.back();
            //this.$router.push('/chatList');
        },
        //发送消息
        sendMessage() {
            //先判断二人是否已经是好友
            const panduanParams = new URLSearchParams;
            panduanParams.append('myId', this.myId);
            panduanParams.append('friendId', this.friendId);
            this.$axios.post('/User/estimateFriendShipByMyIdAndFriendId', panduanParams).then((res) => {
                if (res.data.status != 2) {
                    this.$message.error("你还没有添加对方为好友，不能发送消息！");
                    return;
                }
            })
            if (this.newMessage.trim() !== '') {
                const params = new URLSearchParams();
                params.append('senderId', this.myId);
                params.append('receiverId', this.friendId);
                params.append('content', this.newMessage.trim());
                this.$axios.post('/Message/sendMessageBySIDAndRID', params).then((res) => {
                    console.log(res);
                    //this.messages.push(params);
                    this.newMessage = '';
                    this.getMessages();
                }).catch((error) => {
                    console.error(error);
                });
            }
        },

        //获取消息
        getMessages() {
            var body = {
                myId: this.myId,
                friendId: this.friendId
            }
            this.$axios.post("/Message/getMessageAndNotShowDeleteMessage", body).then((res) => {
                if (res.data.status == 200) {
                    this.messages = res.data.data;
                    //console.log(this.messages);
                } else {
                    console.log("获取消息失败！");
                }
            })
        },

        //排序方法
        sortMessagesBySendTime() {
            this.messages.sort((a, b) => new Date(a.sendTime) - new Date(b.sendTime));
        },
        //获取备注
        getfriendNickname() {
            let userId1 = parseInt(localStorage.getItem('userId'), 10);
            let userId2 = parseInt(localStorage.getItem('userId2'), 10);
            this.$axios.get('/FriendShip/getNickNameByUserId1andUserId2/' + userId1 + "/" + userId2).then((res) => {
                //console.log(res);
                this.friendNickname.nickName = res.data.data[0].nickName;
            })
        },
        //获取我的头像
        getMyavatar() {
            let userId1 = parseInt(localStorage.getItem('userId'), 10);
            this.$axios.get("/User/getUserInformation/" + userId1).then((res) => {
                //console.log(res);
                this.avatar.myavatar = res.data.data[0].avatar;
            })
        },
        //获取好友头像
        getFriendavatar() {
            let userId2 = parseInt(localStorage.getItem('userId2'), 10);
            this.$axios.get("/User/getUserInformation/" + userId2).then((res) => {
                //console.log(res);
                this.avatar.friendavatar = res.data.data[0].avatar;
            })
        },
        //获取我的账号和对方账号
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.friendId = parseInt(localStorage.getItem('userId2'), 10);
        },
        //处理点击“发送图片”按钮的事件
        selectImgFile() {
            this.$refs.ImgFileInput.click();
        },
        //处理点击“发送视频”按钮的事件
        selectVideoFile() {
            this.$refs.VideoFileInput.click();
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中(图片)
        onFileSelectedIsImg(event) {
            this.selectedFile = event.target.files[0];
            this.toggleMenu();
            //调用发送图片的方法
            this.sendImage();
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中(视频)
        onFileSelectedIsVedio(event) {
            this.selectedFile = event.target.files[0];
            this.toggleMenu();
            //调用发送视频的方法
            this.sendVedio();
        },
        //发送图片的方法
        sendImage() {
            if (!this.selectedFile) {
                // 文件未选择，进行处理与提示
                return;
            }
            // const formData = new FormData();
            // formData.append('senderId', parseInt(this.myId));
            // formData.append('receiverId', parseInt(this.friendId));
            // formData.append('messageImg', this.selectedFile);
            // this.$axios.post("/Message/sendImgBySIDAndRID", formData).then((res) => {
            //     console.log(res);
            //     this.getMessages();

            // }).catch((error) => {
            //     console.error(error);
            // });
            //上面被注释的是直接上传，下面是压缩后上传
            this.compressImage(this.selectedFile, 0.8)
                .then((compressedBlob) => {
                    const formData = new FormData();
                    formData.append('senderId', parseInt(this.myId));
                    formData.append('receiverId', parseInt(this.friendId));
                    formData.append('messageImg', compressedBlob);

                    this.$axios.post("/Message/sendImgBySIDAndRID", formData)
                        .then((res) => {
                            console.log(res);
                            this.getMessages();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }).catch((error) => {
                    console.error('压缩图片出错：', error);
                });

        },
        //发送视频的方法
        sendVedio() {
            if (!this.selectedFile) {
                // 文件未选择，进行处理与提示
                return;
            }
            const form = new FormData();
            form.append('senderId', this.myId);
            form.append('receiverId', this.friendId);
            form.append('videoFile', this.selectedFile);
            this.$axios.post("/Message/sendVideoMessageToFriend", form).then((res) => {
                console.log(res);
                this.getMessages();
            })
        },
        //打开/关闭菜单2
        toggleMenu() {
            this.isChatInput2 = !this.isChatInput2;
            this.isChatMessages2 = !this.isChatMessages2;
            if (!this.isCaidan2 && !this.isCaidan3) {
                this.isCaidan2 = true;
            } else if (this.isCaidan2 && !this.isCaidan3) {
                this.isCaidan2 = false;
            } else if (!this.isCaidan2 && this.isCaidan3) {
                this.isCaidan3 = false;
            } else {
                this.isCaidan2 = false;
                this.isCaidan3 = false;
            }
        },
        //打开/关闭菜单3
        openCaidan3() {
            this.isChatInput2 = !this.isChatInput2;
            this.isChatMessages2 = !this.isChatMessages2;
            if (!this.isCaidan2 && !this.isCaidan3) {
                this.isCaidan3 = true;
            } else if (this.isCaidan2 && !this.isCaidan3) {
                this.isCaidan2 = false;
            } else if (!this.isCaidan2 && this.isCaidan3) {
                this.isCaidan3 = false;
            } else {
                this.isCaidan2 = false;
                this.isCaidan3 = false;
            }
        },
        //把好友发给我的消息标为已读 senderId:朋友Id receiverId： 我的Id
        MarkMessageAsRead() {
            var MarkMessageAsReadBody = {
                senderId: this.friendId,
                receiverId: this.myId
            }
            this.$axios.post("/Message/MarkMessageAsRead", MarkMessageAsReadBody).then((res) => {
                console.log(res);
            })
        },
        //打开聊天设置
        toChatSetting() {
            this.$router.push('/chatSetting');
        },
        //跳转到视频通话界面
        toVidio() {
            this.setScrollPoint();
            this.$router.push('/vidio');
        },
        //stomp的建立部分：
        // connectWebSocket() {
        //     const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
        //     this.stompClient = Stomp.over(socket);
        //     this.stompClient.connect({}, this.onConnected, this.onError);
        // },
        // onConnected() {
        //     console.log('WebSocket连接已建立');
        //     // 在连接成功后，可以订阅目标主题
        //     this.stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);  //订阅我的专属地址
        // },
        // onError(error) {
        //     console.error('WebSocket连接错误:', error);
        // },
        // onMessageReceived(message) {
        //     console.log('收到消息:', message.body);
        //     // 处理接收到的消息
        //     this.getMessages();//重新获取消息列表
        // },
        //让滚动条到最底部，以便查看最新消息
        handleScroll(event) {
            const chatContainer = event.target;
            // 判断滚动位置是否接近底部
            this.isScrollAtBottom = chatContainer.scrollHeight - chatContainer.scrollTop <= chatContainer.clientHeight;
        },
        scrollToBottom() {

            const chatContainer = document.querySelector('.chat-messages');
            chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部

        },

        //记录当前滚动条位置
        setScrollPoint() {
            const chatContainer = document.querySelector('.chat-messages');
            //console.log(chatContainer.scrollTop);
            localStorage.setItem('ScrollPoint', chatContainer.scrollTop);
        },
        //使滚动条滚动到记录的位置
        scrollToPoint() {
            if (this.ScrollPoint) {
                const chatContainer = document.querySelector('.chat-messages');
                chatContainer.scrollTop = this.ScrollPoint; // 滚动到指定位置
                localStorage.removeItem('ScrollPoint');
                this.ScrollPoint = null;

            } else {
                this.scrollToBottom();
            }
        },

        //点击图片查看大图（获取到相应的链接）
        getBigImgSrc(message) {
            this.setScrollPoint();
            localStorage.setItem('imgPath', message.imagePath);
            this.$router.push('/lookBigImg');
        },
        //点击输入框触发的事件
        handInput() {
            if (!this.isChatInput2) {
                setTimeout(() => {
                    this.scrollToBottom();
                }, 100);
            }
        },
        // 开始录音
        handleStart() {
            this.recorder = new Recorder({
                sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
                sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
                numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
            }),
                Recorder.getPermission().then(() => {
                    console.log('开始录音')
                    this.recorder.start() // 开始录音
                }, (error) => {
                    this.$message({
                        message: '请先允许该网页使用麦克风',
                        type: 'info'
                    })
                    console.log(`${error.name} : ${error.message}`)
                })
        },
        handleStop() {
            console.log('停止录音')
            this.recorder.stop() // 停止录音
        },
        //发送语音
        sendVoice() {
            this.openCaidan3();

            setTimeout(() => {
                console.log('执行发送（上传）方法');
                const blob = this.recorder.getWAVBlob();
                const newBlob = new Blob([blob]);
                const fileOfBlob = new File([newBlob], new Date().getTime() + '.wav');
                const data = new FormData();
                data.append('senderId', this.myId);
                data.append('receiverId', this.friendId);
                data.append('wavFile', fileOfBlob);

                this.$axios.post("/Message/sendVoiceMessageToFriend", data).then((res) => {
                    console.log(res);
                    this.getMessages();
                })
                this.recorder.destroy() // 销毁实例
            }, 300)
        },
        //去查看全屏视频
        toBigVideo(newVideoPath) {
            this.setScrollPoint();
            localStorage.setItem('newVideoPath', newVideoPath);
            this.$router.push('/BigVideo');
            //console.log(newVideoPath);
        },
        //以下代码用于图片缩放
        onTouchStart(event) {
            if (event.touches.length === 2) {
                this.touchStartDistance = Math.hypot(
                    event.touches[1].clientX - event.touches[0].clientX,
                    event.touches[1].clientY - event.touches[0].clientY
                );
            }
        },
        onTouchMove(event) {
            if (event.touches.length === 2) {
                this.touchMoveDistance = Math.hypot(
                    event.touches[1].clientX - event.touches[0].clientX,
                    event.touches[1].clientY - event.touches[0].clientY
                );
                this.scale = this.touchMoveDistance / this.touchStartDistance;
            }
        },
        onTouchEnd() {
            this.touchStartDistance = 0;
            this.touchMoveDistance = 0;
        },
        onMessageReceived(message) {
            console.log('收到消息:', message.body);
            // 处理接收到的消息
            this.getMessages();
        },

        //图片压缩方法
        compressImage(file, quality) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const maxWidth = 800; // 设置最大宽度
                        const maxHeight = 600; // 设置最大高度

                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob((blob) => {
                            resolve(blob);
                        }, 'image/jpeg', quality);
                    };
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });
        },


    },
    mounted() {
        this.getMessages();
        this.getfriendNickname();
        this.getMyavatar();
        this.getFriendavatar();

        // 监听滚动事件
        const chatContainer = document.querySelector('.chat-messages');
        chatContainer.addEventListener('scroll', this.handleScroll);
        // setTimeout(()=>{
        //     this.scrollToPoint();
        // },50);

        //订阅WebSocket主题
        const stompClient = inject('stompClient');
        setTimeout(() => {
            if (stompClient) {
                stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);
                console.log("chat连接成功");
            };
        }, 500);

    },
    created() {
        this.ScrollPoint = parseInt(localStorage.getItem('ScrollPoint'), 10);
        this.getId();
        //this.connectWebSocket();
        this.recorder = new Recorder({
            sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
            sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
            numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
        });

    },
    watch: {
        messages(newValue, oldValue) {
            if (newValue.length > oldValue.length && this.isScrollAtBottom) {
                this.$nextTick(() => {
                    this.scrollToPoint();
                });
            }
        }
    },
    beforeRouteLeave(to, from, next) {    //路由守卫钩子，当退出当前路由之前会执行此函数
        this.MarkMessageAsRead();
        next();
    }
};
</script>

<style scoped>
.body {
    position: absolute;
    width: 100vw;
    height: 100vh;
    bottom: 0;
}

.friend-avatar {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.my-avatar {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 2px;
}

.chat-header {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
}

.friend-nickname {
    font-size: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 110px);
    padding: 3px;
}

.chat-messages .message img.friend-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.chat-messages .message img.my-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.chat-messages2 {
    flex: 1;
    overflow-y: auto;
    padding: 3px;
    height: calc(100vh - 228px);
}



.message {
    margin-bottom: 10px;
}

.received {
    display: flex;
    /* align-items: flex-start; */
    align-items: center;
}

.sent {
    display: flex;
    /* align-items: flex-end; */
    align-items: center;
}

.message-bubble {
    display: inline-block;
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 8px;
    word-break: break-word;
    margin-left: 5px;
    margin-right: 5px;
}

.message-image {
    width: 100%;
}

.message-image img {
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
}

.received .avatar-container {
    margin-top: auto;

}

.sent .avatar-container {
    margin-top: auto;
    margin-left: 0;
}

.received .message-bubble {
    background-color: #e0e0e0;
    color: black;
    align-self: flex-start;
    white-space: pre-wrap;
}

.sent .message-bubble {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    color: white;
    align-self: flex-end;
    margin-left: auto;
    white-space: pre-wrap;
}

.sent .message-bubble-img {
    color: white;
    align-self: flex-end;
    margin-left: auto;
}

.chat-input {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 45px;
}

.chat-input input {
    flex: 1;
    height: 35px;
    outline: none;
    padding: 0 0 0 10px;
    font-size: 14px;
}

.chat-input button {
    background-color: deepskyblue;
    color: white;
    border: none;
    outline: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.chat-input2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    bottom: 100px;
}

.chat-input2 input {
    flex: 1;
    height: 35px;
    outline: none;
    padding: 0 0 0 10px;
    font-size: 14px;
}

.chat-input2 button {
    background-color: deepskyblue;
    color: white;
    border: none;
    outline: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.caidan {
    position: absolute;
    height: 66px;
    bottom: 0;
    display: none;
}

.caidan2 {
    position: absolute;
    height: 66px;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.caidan3 {
    position: absolute;
    height: 90px;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}

.caidan3 button {
    height: 65px;
    width: 65px;
    border-radius: 52px;
    border: none;
    color: white;
    font-size: 10px;
}

.div_xiaoxi {
    position: relative;
    display: flex;
    width: 100vw;
}

.bigImg {
    width: 100vw;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    background-color: black;
    height: 100vh;
    object-fit: contain;
}

.yuYin_div1 {
    text-align: end;
    width: calc(100vw - 53px);
}

.yuYin_div1 button {
    height: 35px;
    border-radius: 8px;
    border: none;
    color: white;
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    font-size: 14px;
}

.yuYin_div2 {
    text-align: inherit;
    margin-left: 4px;
}

.yuYin_div2 button {
    height: 35px;
    border-radius: 8px;
    border: none;
    background-color: #e0e0e0;
    font-size: 14px;
}

.videoDiv1 {
    width: calc(100vw - 48px);
    display: flex;
    justify-content: end;
    margin-bottom: 4px;
}

.videoDiv2 {
    margin-left: 5px;
}

video {
    width: 60vw;
    height: 40vw;
    object-fit: cover;
    margin-right: 4px;
}
.el-textarea {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    font-size: var(--el-font-size-base);
    flex: 1;
}
</style>
