<template>
    <div class="body">
        <img class="cha" src="../assets/叉叉.png" @click="goBack">
        <video controls autoplay>
            <source :src="this.videoPath" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default{
    data(){
        return{
            videoPath: null
        }
    },
    methods:{
        goBack(){
            this.$router.back();
        },
        getVideoPath(){
            this.videoPath = localStorage.getItem('newVideoPath');
            console.log(this.videoPath);
        }
    },
    mounted(){
        
    },
    created(){
        this.getVideoPath();
    }
}
</script>


<style scoped>
.body{
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
video{
    width: 100vw;
}

.cha{
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
}
</style>