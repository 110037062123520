<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>邀请好友</p>
        </div>
        <div class="friendList">
            <div class="friendInformation" v-for="f in this.FriendList">
                <input type="checkbox" v-model="this.checkedFriendsItems" :value="f.userId2">
                <img :src="f.avatar">
                <p>{{f.nickName}}</p>
            </div>
        </div>
        <div class="bottom">
            <button @click="inviteFriends">确定邀请</button>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            myId: null,
            groupId: null,
            checkedFriendsItems: [],
            FriendList: [], //存放的是朋友列表（是我的好友但不在指定群中)
        }
    },
    methods:{
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId(){
            this.myId = parseInt(localStorage.getItem('userId'),10);
            this.groupId = parseInt(localStorage.getItem('groupId'), 10);
        },
        getFriendList(){
            var body = {
                myId : this.myId,
                groupId : this.groupId
            }
            this.$axios.post("/GroupUser/getFriendsLIstIsMyFriendAndNotInGroup",body).then((res)=>{
                this.FriendList = res.data.data;
                //console.log(this.FriendList);
            })
        },
        //确定邀请的事件
        inviteFriends(){
            var body = {
                groupId : this.groupId,
                userIdList : this.checkedFriendsItems
            }
            this.$axios.post("/GroupUser/inviteFriends",body).then((res)=>{
                if(res.data.status == 200){
                    console.log(res);
                    this.$message.success("邀请成功！");
                    this.goBack();
                }
            })
        }
        

            
        
    },
    mounted(){
        this.getId();
        this.getFriendList();
    }
}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}
.friendList{
    width: 100vw;
    height: calc(100vh - 100px);
    overflow: auto;
}
.friendInformation+.friendInformation{
    margin-top: 2px;
}
.friendInformation{
    display: flex;
    width: 100vw;
    background-color: white;
    align-items: center;
}
.friendInformation input{
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-right: 10px;
}
.friendInformation img{
    width: 45px;
    height: 45px;
    border-radius: 8px;
}
.friendInformation p{
    margin-left: 15px;
    font-weight: bold;
}
.bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aquamarine;
    height: 45px;
}
.bottom button{
    padding: 4px;
    background: deepskyblue;
    color: white;
    border-radius: 8px;
}
</style>