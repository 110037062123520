<template>
    <div class="changePasswordBody">
        <div class="top">
            <img @click="goBack" src="../assets/返回2.png" alt="">
            <p>修改密码</p>
            <button @click="changePassword">完成</button>
        </div>
        <div class="neirong">
            <div class="neirong_div1">
                <p>原密码</p>
                <div><input type="text" v-model="changePasswordForm.password0" placeholder="填写原密码"></div>
            </div>
            <div class="neirong_div1">
                <p>新密码</p>
                <div><input type="text" v-model="changePasswordForm.password1" placeholder="填写新密码"></div>
            </div>
            <div class="neirong_div1">
                <p>确认密码</p>
                <div><input type="text" v-model="changePasswordForm.password2" placeholder="再次确认填写"></div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    data() {
        return {
            changePasswordForm: {
                userId: null,
                password0: null,
                password1: null,
                password2: null
        }

    }
},
methods: {
    goBack() {
        // 处理返回逻辑
        this.$router.back();
    },
    //获取userId
    getMyId(){
        this.changePasswordForm.userId = parseInt(localStorage.getItem('userId'), 10);
    },
    //修改密码
    changePassword(){
        this.$axios.post("/User/changePassword", this.changePasswordForm).then((res) => {
            
            console.log(res);
            if (res.data.status == 2) {
                this.$message.success("密码修改成功，请重新登录！");
                this.$router.push('/WechatLogin');
            } else if (res.data.status == 1) {
                this.$message.error("两次输入的新密码不一致，请检查！");
            } else {
                this.$message.error("原密码不正确,请检查！");
            }
        })
    }
},
created(){
    this.getMyId();
}
}
</script>

<style scoped>
.changePasswordBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background-color: #EAEAEA;
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
}

.top img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.top button {
    position: absolute;
    right: 10px;
    background-color: green;
    color: white;
    width: 3.2rem;
    border-radius: 5px;
}


.neirong .neirong_div1 {
    background-color: white;
    display: flex;
    align-items: center;
}

.neirong p {
    font-size: 1rem;
    margin-left: 10px;
    width: 4.5rem;
}

.neirong input {
    height: 1.5rem;
    width: 12rem;
    margin-left: 10px;
    border-radius: 5px;
    border-color: aqua;
}
</style>