<template>
    <div>
        <div class="top">
            <div class="back"><img @click="goBack" style="height: 25px; width: 25px;margin-left: 10px;"
                    src="../assets/返回2.png"></div>
            <div class="save"><el-button @click="setNickName" type="success">保存</el-button></div>
        </div>
        <hr style="margin: 0;" />
        <div class="title">
            <b>设置及修改备注</b>
        </div>
        <div class="setRemark">
            <p>备注:</p>
            <input type="text" v-model="nickName">
        </div>

    </div>
</template>
<script>

export default {
    data() {
        return {
            nickName: null, //备注
            myId: null,
            friendId: null
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        },
        //获取Id
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.friendId = parseInt(localStorage.getItem('userId2'), 10);
            console.log("myId:"+this.myId);
            console.log("friendId:"+this.friendId);
        },
        //获取原始备注，作为填充放在输入框内
        getNickName() {
            this.$axios.get('/FriendShip/getNickNameByUserId1andUserId2/' + this.myId + "/" + this.friendId).then((res) => {
                //console.log(res);
                this.nickName = res.data.data[0].nickName;
            })
        },
        //设置备注的方法
        setNickName(){
            const params = new URLSearchParams;
            params.append('userId1',this.myId);
            params.append('userId2',this.friendId);
            params.append('nickName',this.nickName);
            this.$axios.post('/FriendShip/updateNickName',params).then((res)=>{
                console.log(res);
                if(res.data.status==1){
                    this.$message.success("备注修改成功！");
                }else{
                    this.$message.error("备注修改失败！");
                }
            })
        }
    },
    created(){
        this.getId();
    },
    mounted(){
        this.getNickName();
    }
}
</script>

<style scoped>
.top {
    background-color: aliceblue;
    display: flex;
    align-items: center;
    height: 50px;
}

.top .back {
    flex: 1;
}

.top .save {
    margin-right: 10px;
}

.title {
    text-align: center;
    font-size: 22px;
    margin-top: 20px;
}

.setRemark {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
}

.setRemark input {
    width: 80%;
    height: 30px;
    border-color: cornflowerblue;
    border-radius: 12px;
    font-size: 18px;
}
</style>