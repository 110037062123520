<template>
    <div class="newFriendBody">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>新的朋友</p>
        </div>
        <div class="biaoqianBody">
            <div class="biaoqian" @click="toNewFriend" v-for="a in applicatFriendToMeList">
                <div class="img_div"><img :src="a.avatar"></div>
                <p class="p1">{{ a.userName }} 请求添加好友</p>
                <!-- <p class="p2" style="color: orange;" v-if="a.status === 0">待处理</p> -->
                <p class="p2" style="color: green;" v-if="a.status === 1">已添加</p>
                <p class="p2" style="color: red;" v-else-if="a.status === -1">已拒绝</p>
                <div class="button_div" v-if="a.status === 0">
                    <!-- <button @click="refuseApplicat(a)" class="button1">拒绝</button> -->
                    <el-popconfirm title="确定拒绝该好友申请吗?" @confirm="refuseApplicat(a)">
                        <template #reference>
                            <el-button class="button1">拒绝</el-button>
                        </template>
                    </el-popconfirm>
                    <button @click="agreeApplicat(a)" class="button2">同意</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            myInformation: {
                myId: null,
                userName: '',
            },
            applicatFriendToMeList: []
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //我的信息
        getMyINfromation() {
            let myId = parseInt(localStorage.getItem('userId'), 10);
            this.myInformation.myId = myId  //我的id
            this.$axios.get("/User/getUserInformation/" + myId).then((res) => {
                this.myInformation.userName = res.data.data[0].userName;
                // console.log("我的id：" + this.myInformation.userId);
                // console.log("我的昵称：" + this.myInformation.userName);
            })
        },
        //获取请求添加我为好友的列表
        getApplicatFriendToMeList() {
            this.$axios.get("/ApplicatFriend/getApplicatFriendToMe/" + this.myInformation.myId).then((res) => {
                console.log(res);
                this.applicatFriendToMeList = res.data.data;
                console.log(this.applicatFriendToMeList);
            })
        },
        //同意好友申请
        agreeApplicat(a) {
            //判断二人是否已经是好友
            const panduanParams = new URLSearchParams;
            panduanParams.append('myId', this.myInformation.myId);
            panduanParams.append('friendId', a.userId);

            this.$axios.post('/User/estimateFriendShipByMyIdAndFriendId', panduanParams).then((res) => {
                console.log(res);
                if (res.data.status === 2) {
                    this.$message.error("你二人已经是好友，无需重复添加！")
                } else if (this.myInformation.myId === a.userId) {
                    this.$message.error("不能添加自己为好友！");
                } else {
                    //把status变为1，在好友列表中插入数据
                    var agreeApplicatFriendBody = {
                        senderId: a.userId,   //申请者的Id
                        receiverId: this.myInformation.myId,  //我的Id
                        myUserName: this.myInformation.userName,  //接收方的userName，我的
                        friendUserName: a.userName  //申请方的userName
                    }
                    this.$axios.post("/ApplicatFriend/agreeApplicatFriend", agreeApplicatFriendBody).then((res) => {
                        if (res.data.status === 200) {
                            this.$message.success("添加成功！");
                            this.getApplicatFriendToMeList();
                        } else {
                            this.$message.error("添加失败！");
                            this.getApplicatFriendToMeList();
                        }
                    })
                }
            })
        },
        //拒绝好友申请
        refuseApplicat(a) {
            //把status变为-1
            var refuseApplicatFriendBody = {
                senderId: a.userId,
                receiverId: this.myInformation.myId
            }
            this.$axios.post("/ApplicatFriend/refuseApplicatFriend", refuseApplicatFriendBody).then((res) => {
                if (res.data.status = 200) {
                    this.$message.success("已拒绝");
                    this.getApplicatFriendToMeList();
                } else {
                    this.getApplicatFriendToMeList();
                }
            })
        }
    },
    created() {
        this.getMyINfromation();
        this.getApplicatFriendToMeList();
    }
}
</script>

<style scoped>
.newFriendBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.biaoqianBody {
    overflow: auto;
    height: calc(100vh - 55px);
}

.biaoqian {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
}

.biaoqian .img_div {
    margin-left: 15px;
}

.biaoqian img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 6px;
}

.biaoqian .p1 {
    margin-left: 15px;
    font-size: 0.9rem;
    flex: 1;
}

.biaoqian .p2 {
    margin-top: 2px;
    font-size: 0.9rem;
    color: gray;
    margin-right: 10px;
}

.biaoqian+.biaoqian {
    margin-top: 2px;
}

.button_div .button1 {
    background-color: #4169E1;
    border-color: blue;
    color: white;
    width: 48px;
    height: 25px;
    border-radius: 0;
}

.button_div .button2 {
    margin-right: 5px;
    margin-left: 5px;
    background-color: #FFD700;
    border-color: orange;
    width: 48px;
    height: 25px;
}
</style>