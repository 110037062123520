<template>
    <div class="newFriendBody">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>我发出的好友申请</p>
        </div>
        <div class="biaoqianBody">
            <div class="biaoqian" v-for="a in ISendApplicatFriendList">
                <div class="img_div"><img :src=a.avatar></div>
                <p class="p1">{{ a.userName }}</p>
                <p class="p2" style="color: orange;" v-if="a.status === 0">待处理</p>
                <p class="p2" style="color: green;" v-else-if="a.status === 1">已添加</p>
                <p class="p2" style="color: red;" v-else-if="a.status === -1">已拒绝</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ISendApplicatFriendList: [],
            myId: null
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getMyId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        //获取我发出的好友申请列表
        getISendApplicatFriend() {
            this.$axios.get("/ApplicatFriend/getISendApplicatFriend/" + this.myId).then((res) => {
                this.ISendApplicatFriendList = res.data.data;
                console.log(this.ISendApplicatFriendList);
            })
        }

    },
    created() {
        this.getMyId();
        this.getISendApplicatFriend();
    }
}
</script>


<style scoped>
.newFriendBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.biaoqianBody{
    overflow: auto;
    height: calc(100vh - 55px);
}

.biaoqian {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
}

.biaoqian .img_div {
    margin-left: 15px;
}

.biaoqian img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 6px;
}

.biaoqian .p1 {
    margin-left: 15px;
    font-size: 0.9rem;
    flex: 1;
}

.biaoqian .p2 {
    margin-top: 2px;
    font-size: 0.9rem;
    color: gray;
    margin-right: 10px;
}

.biaoqian+.biaoqian {
    margin-top: 2px;
}

.button_div .button1 {
    background-color: #4169E1;
    border-color: cornflowerblue;
    color: white;
}

.button_div .button2 {
    margin-right: 5px;
    margin-left: 5px;
    background-color: #FFD700;
    border-color: orange;
}
</style>