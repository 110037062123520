<template>
    <div>
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>更改群聊名称</p>
        </div>
        <div class="updateName">
            <input v-model="this.groupName" type="text" >
            <el-button @click="changeGroupName" type="primary" round>保存</el-button>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            myId: null,
            groupId: null,
            groupName: null,  //最初的群名
        }
    },
    methods:{
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.groupId = parseInt(localStorage.getItem('groupId'), 10);
        },
        getInitialGroupName(){
            this.groupName = localStorage.getItem('initialGroupName');
        },
        //修改的方法
        changeGroupName(){
            var body = {
                groupName : this.groupName,
                groupId : this.groupId
            }
            this.$axios.post("/Group/changeGroupNameByGroupId",body).then((res)=>{
                if(res.data.status == 200){
                    this.$message.success(res.data.data);
                    this.goBack();
                }else{
                    this.$message.error(res.data.data);
                }
            })
        }
    },
    mounted(){
        this.getId();
        this.getInitialGroupName();
    }
}
</script>
<style scoped>
.top {
    background-color: #ccc;
    display: flex;
    align-items: center;
    height: 55px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 23px;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.updateName {
    display: flex;
    align-items: center;
    margin: 15px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 30px;
}

.updateName input {
    flex: 1;
    border: none;
    outline: none;
    margin-top: 10px;
}
</style>