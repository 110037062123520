<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>聊天记录管理</p>
        </div>
        <div class="center">
            <div class="neiRong" v-for="i in this.chatHistory">
                <div class="whiteKuang">
                    <div class="whiteKuang_top">
                        <img v-if="i.senderId == this.myId" class="avatar" :src="this.myInformation.avatar" alt="">
                        <img v-else class="avatar" :src="this.friendInformation.avatar" alt="">
                        <p v-if="i.senderId == this.myId" class="nickName">{{ this.myInformation.userName }}</p>
                        <p v-else class="nickName">{{ this.friendInformation.userName }}</p>
                        <div>
                            <span id="span1">{{ i.sendTime }}</span>
                            <br>
                            <span id="span2" @click="deleteOneChatMessage(i.messageId)">删除</span>
                        </div>
                    </div>
                    <div class="chatContent">
                        <span v-if="i.content != null">{{ i.content }}</span>
                        <img v-if="i.imagePath" :src="i.imagePath" alt="">
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
export default {
    data() {
        return {
            myId: null,
            friendId: null,
            chatHistory: [],   //历史聊天记录
            myInformation: {
                avatar: null, //头像
                userName: null //昵称
            },
            friendInformation: {
                avatar: null, //头像
                userName: null //昵称
            }
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.friendId = parseInt(localStorage.getItem('userId2'), 10);
            //console.log("该朋友的Id为："+this.friendId);
        },
        // getChatHistory(){
        //     var body ={
        //         myId: this.myId,
        //         friendId: this.friendId
        //     }
        //     this.$axios.post("/Message/getChatHistory",body).then((res)=>{
        //         this.chatHistory = res.data.data;
        //         //console.log(this.chatHistory);
        //     })
        // },
        //获取消息(不展示已经被标为删除的消息)
        getMessages() {
            var body = {
                myId: this.myId,
                friendId: this.friendId
            }
            this.$axios.post("/Message/getMessageAndNotShowDeleteMessage", body).then((res) => {
                if (res.data.status == 200) {
                    this.chatHistory = res.data.data;
                    console.log(this.chatHistory);
                } else {
                    console.log("获取消息失败！");
                }
            })
        },
        //根据userId获取除密码外的其他信息
        getMyInformation() {
            this.$axios.get("User/getUserInformation/" + this.myId).then((res) => {
                console.log(res);
                this.myInformation.avatar = res.data.data[0].avatar;
                this.myInformation.userName = res.data.data[0].userName;
            })
        },
        getFriendInformation() {
            this.$axios.get("User/getUserInformation/" + this.friendId).then((res) => {
                console.log(res);
                this.friendInformation.avatar = res.data.data[0].avatar;
                this.friendInformation.userName = res.data.data[0].userName;
            })
        },
        //根据消息Id删除单条聊天记录的方法
        deleteOneChatMessage(messageId){
            var body = {
                handleUserId : this.myId,
                dedletedMessageId : messageId
            }
            this.$axios.post("/Message/deleteOneChatMessage",body).then((res)=>{
                if(res.data.status == 200){
                    this.getMessages();
                    this.$message.success("删除成功");
                }else{
                    this.$message.error("删除失败");
                }
            })
        }
    },
    mounted() {

    },
    created() {
        this.getId();
        this.getMyInformation();
        this.getFriendInformation();
        setTimeout(() => {
            this.getMessages();
        },50);
    }
}
</script>
<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.neiRong {
    overflow: auto;
}

.whiteKuang {
    width: 100vw;
    background-color: white;
    border-radius: 10px;
}

.whiteKuang_top {
    width: 100vw;
    height: 50px;
    display: flex;
}

.whiteKuang_top .avatar {
    width: 45px;
    height: 45px;
    object-fit: cover;
    margin-left: 5px;
}

.whiteKuang_top .nickName {
    margin-top: 10px;
    margin-left: 6px;
    flex: 1;
}

.whiteKuang_top #span1 {
    font-size: 13px;
    color: gray;
    margin-right: 10px;
}

.whiteKuang_top #span2 {
    color: blue;
    font-size: 13px;
    margin-left: 65px;
}

.chatContent {
    width: 100vw;
    margin: 5px 0 5px 0;
    height: auto;
}

.chatContent span {
    margin-left: 10px;
}

.chatContent img{
    width: 25vw;
    height: 25vw;
    object-fit: cover;
    margin-left: 10px;
}

.center {
    height: calc(100vh - 55px);
    overflow: auto;
}
</style>