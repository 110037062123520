<template>
  <div class="login-container">
    <!-- <h1>登录</h1> -->
    <form @submit.prevent="login">
      <label for="userId">用户名:</label>
      <input type="text" id="userId" v-model="form.userId" required><br />
      <label for="password">密码:</label>
      <input type="password" id="password" v-model="form.password" required><br />
      <button type="submit">登录</button>
      <p><a @click="ToEnroll">没有账号？去注册！</a></p>
    </form>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      form: {
        userId: '',
        password: ''
      }
    };
  },
  methods: {
    //登录
    login() {
      this.$axios.post('/User/login', this.form).then((res) => {
        //console.log(res);
        if (res.data.status == 200) {
          this.$message.success(res.data.message);
          //token保存到sessionStorage中
          window.sessionStorage.setItem("token", res.data.data.token);
          // 登录成功后，保存用户ID到本地
          localStorage.setItem("userId", res.data.data.userId);
          this.$router.push('/myView');
        } else {
          this.$message.error("登陆失败，请检查账号或密码！");
        }
      })
    },
    //去注册
    ToEnroll() {
      this.$router.push("/enroll");
    }


  }
};
</script>
  
<style scoped>
.login-container {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

form {
  width: 75%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 0.8rem;
  background-color: #2196f3;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

a {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
}

a:hover {
  color: #ccc;
}
</style>
  