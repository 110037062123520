<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>选择头像</p>
            <el-button style="position: absolute; right: 10px;" type="primary" @click="onCropConfirm">确认</el-button>
        </div>
        <div class="qita">
            <!-- 原图片 -->
            <div>
                <input type="file" @change="onSelectFile" accept="image/*">
                <p>原图片</p>
                <img ref="image" style="max-width: 100%;" />
            </div>
            <!-- 裁剪后的图片 -->
            <div>
                <p>裁剪后的图片</p>
                <img :src="this.croppedImage" style="width: 100vw;">
            </div>
        </div>
    </div>
</template>
<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

export default {
    data() {
        return {
            myId: null,
            cropper: null,
            croppedImage: null, // 存储裁剪后的图片
            imageFile: null, //裁剪后的图片（BLOD格式）
        };
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        onSelectFile(event) {
            const files = event.target.files;
            if (files && files.length > 0) {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.$refs.image.src = e.target.result;
                    this.initCropper();
                };
                fileReader.readAsDataURL(files[0]);
            }
        },
        initCropper() {
            this.cropper && this.cropper.destroy(); // 如果已经有实例了，先销毁
            this.$nextTick(() => {
                this.cropper = new Cropper(this.$refs.image, {
                    aspectRatio: 16 / 16, // 可以根据需求调整
                    crop: () => {
                        const canvas = this.cropper.getCroppedCanvas();
                        this.croppedImage = canvas.toDataURL('image/png'); // 将裁剪后的图片转换为DataURL
                    },
                });
            });
        },
        // 点击确认后的事件
        onCropConfirm() {
            if (!this.cropper) {
                return;
            }
            // 获取裁剪后的Canvas
            const canvas = this.cropper.getCroppedCanvas();

            this.cropper.getCroppedCanvas().toBlob((blob) => {
                // 此处的blob就是转换后的文件格式对象
                //console.log(blob);
                // 你现在可以处理blob，例如上传到服务器等
                this.compressImage(blob, 0.8).then((compressedBlob) => {
                    const params = new FormData;
                    params.append("userId", this.myId);
                    params.append("avatarFile", compressedBlob);
                    this.$axios.post("/User/changeAvatarByUserId", params).then((res) => {
                        console.log(res);
                        if (res.data.status == 1) {
                            this.$message.success("修改成功！");
                            this.goBack();
                        } else {
                            this.$message.success("修改失败！");
                        }
                    })
                });
            }, 'image/png');
            if (canvas) {
                // 将裁剪后的图片转换为DataURL
                this.croppedImage = canvas.toDataURL('image/png');
                // 这里可以执行其他操作，比如更新UI或上传图片
                //console.log('Cropped Image Data URL:', this.croppedImage);
                // 可选：隐藏或销毁cropper
                this.cropper.destroy();
                this.cropper = null;
            }
            //this.changeAvatarByUserId();
        },
        //上传头像到后端
        // changeAvatarByUserId() {
        //     if (!this.imageFile) {
        //         // 文件未选择，进行处理与提示
        //         console.log("请选择头像！！！");
        //         window.alert('请选择头像');
        //     }
        //     const params = new FormData;
        //     params.append("userId", this.myId);
        //     params.append("avatarFile", this.imageFile, 'avatar.png');
        //     this.$axios.post("/User/changeAvatarByUserId", params).then((res) => {
        //         console.log(res);
        //         if (res.data.status == 1) {
        //             this.$message.success("修改成功！");
        //         } else {
        //             this.$message.success("修改失败！");
        //         }
        //     });
        // },
        // 修改压缩图片方法，支持传入 Blob 类型参数
        compressImage(blob, quality) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = URL.createObjectURL(blob);

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // 设置画布尺寸与图片相同
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;

                    // 绘制图片到画布并压缩
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((compressedBlob) => resolve(compressedBlob), 'image/jpeg', quality);
                };

                img.onerror = (error) => reject(error);
            });
        },

    },
    mounted() {
        this.getId();
    }
};


</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.qita {
    width: 100vw;
    height: calc(100vh - 55px);
    overflow: auto;
}
</style>