<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>群信息</p>
        </div>
        <div class="qita">
            <div class="groupUser">
                <div class="user" v-for="g in this.groupUserInformation">
                    <img class="avatar" :src="g.avatar" @click="toSeeGroupUserInformation(g.userId)">
                    <p>{{ g.userName }}</p>
                </div>
            </div>
            <div class="ManagementGroup">
                <div class="card">
                    <div class="avatar" @click="toUploadGroupAvatar">
                        <p>群头像</p>
                        <img :src="this.groupInformation.groupAvatar" alt="">
                        <span>></span>
                        <input type="file" style="display: none;" accept="image/*" ref="fileInput"
                            @change="onFileSelected">
                    </div>
                    <hr>
                    <div class="text" @click="toChangeGroupName">
                        <p class="p1">群聊名称</p>
                        <p class="p2">{{ this.groupInformation.groupName }}</p>
                        <span>></span>
                    </div>
                    <hr>
                    <div class="text">
                        <p class="p1">群号</p>
                        <p class="p2">{{ this.groupId }}</p>
                    </div>
                    <hr>
                    <div class="avatar">
                        <p>邀请好友</p>
                        <img @click="toInviteFriends" src="../assets/加号5.png" alt="">
                    </div>
                    <hr>
                    <div class="button_div" v-if="this.myId != this.groupOwnerId">
                        <button @click="openDeleteVisBale">退出群聊</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 确认删除的模态框 -->
        <div class="mtkBody" v-if="this.dedleteVisBale">
            <div class="motaikuang">
                <div class="motaikuang_top">
                    <p class="p1">提示</p>
                    <p class="p2">确定退出群聊?</p>
                </div>
                <div class="motaikuang_bottom">
                    <button class="button1" @click="closeDeleteVisBale">取消</button>
                    <button class="button2" @click="quitGroup">删除</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            groupId: null,
            groupUserInformation: [],
            groupInformation: {
                groupName: null,
                createrId: null,
                groupAvatar: null
            },
            groupOwnerId: null, //群主Id
            selectedFile: null, //设置一个变量来存储选择的头像文件
            dedleteVisBale : false  //确定删除(退群)的模态框
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        },
        //处理点击“更改头像”按钮的事件
        selectFile() {
            this.$refs.fileInput.click();
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中
        onFileSelected(event) {
            this.selectedFile = event.target.files[0];

            this.changeGroupAvatarByGroupId();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.groupId = parseInt(localStorage.getItem('groupId'), 10);
        },
        getGroupUserInformation() {
            const params = new URLSearchParams;
            params.append('groupId', this.groupId);
            this.$axios.post("/GroupUser/getGroupUserInformation", params).then((res) => {
                this.groupUserInformation = res.data.data;
                //console.log(this.groupUserInformation);
            })
        },
        //根据群号查询群基本信息
        getGroupInformation() {
            const params = new URLSearchParams;
            params.append('groupId', this.groupId);
            this.$axios.post("/Group/getGroupInformationByGroupId", params).then((res) => {
                this.groupInformation.groupName = res.data.data[0].groupName;
                this.groupInformation.groupAvatar = res.data.data[0].groupAvatar;
                this.groupInformation.createrId = res.data.data[0].createrId;
                this.groupOwnerId = res.data.data[0].createrId;
                //console.log(this.groupInformation.groupName);
                //console.log(res);
            })
        },
        toChangeGroupName() {
            this.$router.push('/changeGroupName');
            localStorage.setItem('initialGroupName', this.groupInformation.groupName);
        },
        //修改头像的方法
        changeGroupAvatarByGroupId() {
            const params = new FormData;
            params.append('groupId', this.groupId);
            params.append('avatarFile', this.selectedFile);
            this.$axios.post("/Group/changeGroupAvatarByGroupId", params).then((res) => {
                if (res.data.status == 200) {
                    this.$message.success("修改成功");
                    this.getGroupInformation();
                }
            })
        },
        //去邀请好友的界面
        toInviteFriends() {
            this.$router.push('/inviteFriends');
        },
        //去修改头像的界面
        toUploadGroupAvatar() {
            this.$router.push('/uploadGroupAvatar');
        },
        //点击头像去群友信息界面
        toSeeGroupUserInformation(groupUserId) {
            //先判断此人是不是我的好友
            const params1 = new URLSearchParams;
            params1.append('myId', this.myId);
            params1.append('friendId', groupUserId);
            this.$axios.post('User/estimateFriendShipByMyIdAndFriendId', params1).then((res) => {
                //console.log(res);
                if (res.data.status == 2) {
                    //是好友，跳转到好友信息界面
                    localStorage.setItem('userId2', groupUserId);
                    this.$router.push('/friendInformation');
                } else if (groupUserId == this.myId) {
                    //暂时不执行任何操作
                } else {
                    //不是好友，跳转到陌生人信息界面
                    localStorage.setItem('groupUserId', groupUserId);
                    this.$router.push('/strangerInformation');
                }

            })
        },
        //退出群聊的方法
        quitGroup() {
            var body = {
                userId: this.myId,
                groupId: this.groupId
            }
            this.$axios.post("/GroupUser/quitGroup", body).then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                    this.$message.success("已成功退出群聊！");
                    this.$router.replace('/groupChat');
                }
            })
        },
        //打开确定删除动态的模态框
        openDeleteVisBale(){
            this.dedleteVisBale = true;
        },
        //关闭确定删除动态的模态框
        closeDeleteVisBale(){
            this.dedleteVisBale = false;
        }
    },
    mounted() {
        this.getId();
        this.getGroupUserInformation();
        this.getGroupInformation();
    }
}
</script>
<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.qita {
    width: 100vw;
    height: calc(100vh - 55px);
    overflow: auto;
}

.groupUser {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.user {
    margin-bottom: 5px;
}

.groupUser .user p {
    margin: 0;
    text-align: center;
    color: gray;
    font-size: 13px;
}

.groupUser .avatar {
    width: 18vw;
    height: 18vw;
    border-radius: 8px;
}

.ManagementGroup {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.ManagementGroup .card {
    width: 90vw;
    background-color: white;
    border-radius: 12px;
}

.ManagementGroup .card span {
    margin-right: 10px;
    color: blue;
}

.ManagementGroup .card .avatar {
    display: flex;
    width: 90vw;
    margin-top: 5px;
    align-items: center;
}

.ManagementGroup .card .avatar p {
    flex: 1;
    margin-left: 20px;
}

.ManagementGroup .card .avatar img {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    margin-right: 8px;
}

hr {
    width: 85vw;
}

.ManagementGroup .card .text {
    display: flex;
    align-items: center;
}

.ManagementGroup .card .text .p1 {
    flex: 1;
    margin-left: 20px;
}

.ManagementGroup .card .text .p2 {
    margin-right: 13px;
}

.ManagementGroup .card .button_div {
    display: flex;
    justify-content: center;
}

.ManagementGroup .card .button_div button {
    padding: 8px;
    margin: 5px;
    width: 110px;
    border-radius: 8px;
    background-color: orangered;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 0 5px 5px 0 rgb(255 69 0 / 40%);
}
.mtkBody {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.motaikuang {
    width: 85vw;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%);
}
.motaikuang .motaikuang_top{
    height: 80px;
    text-align: center;
}
.motaikuang .motaikuang_top .p1{
    font-weight: bold;
    margin-top: 20px;
    font-size: 20px;
}
.motaikuang .motaikuang_top .p2{
    color: darkslategray;
}

.motaikuang .motaikuang_bottom{
    width: 85vw;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center
}
.motaikuang .motaikuang_bottom .button1{
    height: 30px;
    width: 60px;
    border: none;
    background: lightgray;
    border-radius: 6px;
}
.motaikuang .motaikuang_bottom .button2{
    height: 30px;
    width: 60px;
    border: none;
    background: dodgerblue;
    border-radius: 6px;
    color: wheat;
}
</style>