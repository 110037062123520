<template>
    <div class="body">
        <div class="top">
            <div id="back"><img @click="goBack" src="../assets/navigate-left.png"></div>
            <p>群友信息</p>
        </div>
        <div class="center">
            <div class="friendAvatar">
                <img :src="this.groupUserInformation.avatar">
            </div>
            <div class="friendInformation">
                <p id="nicheng">昵称: {{ this.groupUserInformation.userName }}</p>
                <p id="wxhao">微信号: {{ this.groupUserId }}</p>
            </div>
        </div>
        <div class="applyKuang" @click="applyAddFriend">
            <p>申请添加为好友</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            groupUserId: null,
            groupUserInformation: {
                userName: null,
                avatar: null
            }
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.groupUserId = parseInt(localStorage.getItem('groupUserId'), 10);
        },
        //通过群友Id查询昵称和头像
        getgroupUserInformation() {
            this.$axios.get('/User/getUserInformation' + '/' + this.groupUserId).then((res) => {
                //console.log(res);
                this.groupUserInformation.userName = res.data.data[0].userName;
                this.groupUserInformation.avatar = res.data.data[0].avatar;
            })
        },
        //申请加为好友的方法
        applyAddFriend() {
            //发送好友请求给对方
            var form = {
                senderId: this.myId,
                receiverId: this.groupUserId
            }
            this.$axios.post("/ApplicatFriend/sendApplicatAddFriend", form).then((res) => {
                if (res.data.status === 200) {
                    this.$message.success("好友请求已发送，等待对方回应");
                } else {
                    this.$message.error("发送失败！");
                }
            })
        }
    },
    mounted() {
        this.getId();
        this.getgroupUserInformation();
    }
}
</script>

<style scoped>
.body {
    background-color: gainsboro;
    width: 100vw;
    height: 100vh;
}

.top {
    display: flex;
    background-color: white;
    height: 55px;
    align-items: center;
}

.top img {
    width: 25PX;
    height: 25px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.center {
    display: flex;
    width: 100%;
    background-color: white;
}

.friendAvatar {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.friendAvatar img {
    width: 7rem;
    height: 7rem;
    border-radius: 10px;
}

.friendInformation {
    width: 60%;
    margin-left: 10px;
}

.friendInformation #beizhu {
    font-size: 25px;
}

.friendInformation #nicheng {
    color: darkslategrey;
    font-weight: bold;
    font-size: 20px;
}

.friendInformation #wxhao {
    color: darkslategrey;
    font-weight: bold;
    font-size: 20px;
}

hr {
    height: 0;
    margin: 0;
    padding: 0;
}

.applyKuang {
    background-color: white;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.applyKuang p {
    font-size: 20px;
    color: blue;
}
</style>