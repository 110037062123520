<template>
    <div class="body">
        <div class="div1">
            <video controls>
                <source src="http://localhost:9000/img/bc1607b2-51b4-4040-93a9-ccfd838a08d1" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>


</script>


<style scoped>
.body{
    width: 100vw;
    height: 100vh;
}

.div1{
    width: 100vw;
}

.div1 video{
    width: 100vw;
}

</style>