<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>发表动态</p>
            <el-button @click="publishDynamic" style="position: absolute;right: 15px;" size="small"
                type="primary">发布</el-button>
        </div>
        <div class="textPart">
            <el-input v-model="this.content" :rows="5" type="textarea" placeholder="文字内容..." />
        </div>
        <div class="imagePart">
            <img v-if="this.img0" src="../assets/多素材.png" @click="selectFile">
            <img v-if="this.selectImgUrlList != null" :src="i" v-for="i in this.selectImgUrlList">
            <input type="file" accept="image/*" multiple @change="handleFileChange" ref="fileInput"
                style="display: none;">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            img0: true,
            content: null,
            dynamicImages: [],
            selectImgUrlList: [], //选择的图片地址存放的列表
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        handleFileChange(event) {
            const fileList = event.target.files; // 获取选择的文件列表
            this.dynamicImages = Array.from(fileList); // 将文件列表转为数组并保存到 data 属性中
            console.log(this.dynamicImages);
            this.img0 = false;
            // 遍历 dynamicImages 数组，生成 URL 地址并存储到 url 数组中
            for (let i = 0; i < this.dynamicImages.length; i++) {
                const file = this.dynamicImages[i];
                const url = URL.createObjectURL(file);
                this.selectImgUrlList.push(url);
            }

            console.log(this.selectImgUrlList);
        },
        //处理点击“选择图片”的事件
        selectFile() {
            this.$refs.fileInput.click();
        },
        //发布动态的函数
        // publishDynamic() {
        //     if (this.dynamicImages.length == 0) {
        //         // 文件未选择，进行处理与提示
        //         this.$message.info("请选择图片");
        //         return;
        //     }
        //     const formData = new FormData();
        //     formData.append("userId", this.myId);
        //     formData.append("content", this.content);
        //     for (let i = 0; i < this.dynamicImages.length; i++) {
        //         formData.append("dynamicImages", this.dynamicImages[i]);
        //     }
        //     this.$axios.post("/Dynamic/publishDynamic", formData).then((res) => {
        //         console.log(res);
        //         if (res.data.status == 200) {
        //             this.$message.success("发布成功！");
        //             this.$router.push("/dynamicSpace")
        //         } else {
        //             this.$message.error("发布失败！");
        //         }
        //     })

        // },
        publishDynamic() {
            if (this.dynamicImages.length === 0) {
                // 文件未选择，进行处理与提示
                this.$message.info("请选择图片");
                return;
            }

            const formData = new FormData();
            formData.append("userId", this.myId);
            formData.append("content", this.content);

            const compressPromises = this.dynamicImages.map((image) => {
                return this.compressImage(image, 0.8);
            });

            Promise.all(compressPromises)
                .then((compressedBlobs) => {
                    for (let i = 0; i < compressedBlobs.length; i++) {
                        formData.append("dynamicImages", compressedBlobs[i]);
                    }

                    this.$axios.post("/Dynamic/publishDynamic", formData)
                        .then((res) => {
                            console.log(res);
                            if (res.data.status === 200) {
                                this.$message.success("发布成功！");
                                this.$router.push("/dynamicSpace");
                            } else {
                                this.$message.error("发布失败！");
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.$message.error("发布失败！");
                        });
                })
                .catch((error) => {
                    console.error('图片压缩出错：', error);
                    this.$message.error("发布失败！");
                });
        },
        //图片压缩方法
        compressImage(file, quality) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const maxWidth = 800; // 设置最大宽度
                        const maxHeight = 600; // 设置最大高度

                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob((blob) => {
                            resolve(blob);
                        }, 'image/jpeg', quality);
                    };
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });
        },

    },
    created() {
        this.getId();
    }
}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}



.imagePart {
    background-color: white;
    margin-top: 5px;
    border-radius: 10px;
    min-height: 100px;
    max-height: 250px;
    overflow: auto;
}

.imagePart img {
    height: 100px;
    width: 100px;
    margin-left: 5px;
}
</style>