<template>
    <div>
        <div class="top">
            <img @click="goBack" style="height: 25px; width: 25px;margin-left: 10px;" src="../assets/返回2.png">
            <p>添加朋友</p>
        </div>
        <div class="search-box">
            <input v-model="selectId" type="text" placeholder="输入微信号搜索...">
            <el-button @click="getUserInformation" type="primary" round>搜索</el-button>
        </div>
        <div v-if="userInformation.userId !== null" class="ContactList">
            <img :src="userInformation.avatar">
            <span class="nicheng"><b>{{ userInformation.userName }}</b></span>
            <el-button @click="addFriendByUserId1AndUserId2" style="margin-right: 15px;" type="primary"
                round>添加好友</el-button>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            myInformation: {
                userId: null,
                userName: '',
            },
            selectId: null,
            userInformation: {
                userId: null,
                userName: '',
                avatar: ''
            }
        }
    },
    methods: {
        //我的信息
        getMyINfromation() {
            let myId = parseInt(localStorage.getItem('userId'), 10);
            this.myInformation.userId = myId  //我的id
            this.$axios.get("/User/getUserInformation/" + myId).then((res) => {
                this.myInformation.userName = res.data.data[0].userName;
                console.log("我的id：" + this.myInformation.userId);
                console.log("我的昵称：" + this.myInformation.userName);
            })
        },
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //通过userId查询用户信息
        getUserInformation() {
            this.$axios.get('/User/getUserInformation/' + this.selectId).then((res) => {
                console.log(res);
                if (res.data.status === 1) {
                    this.userInformation.userId = res.data.data[0].userId;
                    this.userInformation.userName = res.data.data[0].userName;
                    this.userInformation.avatar = res.data.data[0].avatar;
                }else{
                    this.$message.error("未查询到用户信息！");
                }

            })
        },
        //添加好友的方法，目前瑕疵在于无需对方同意便可添加，后续改进（备注默认为对方昵称）
        addFriendByUserId1AndUserId2() {

            //先判断二人是否已经是好友，如果已经互为好友则不能重复添加
            const panduanParams = new URLSearchParams;
            panduanParams.append('myId', this.myInformation.userId);
            panduanParams.append('friendId', this.userInformation.userId);

            this.$axios.post('/User/estimateFriendShipByMyIdAndFriendId', panduanParams).then((res) => {
                console.log(res);
                if (res.data.status === 2) {
                    this.$message.error("你二人已经是好友，无需重复添加！")
                } else if (this.myInformation.userId === this.userInformation.userId) {
                    this.$message.error("不能添加自己为好友！");
                } else {
                    //发送好友请求给对方
                    var form = {
                        senderId: this.myInformation.userId,
                        receiverId: this.userInformation.userId
                    }
                    this.$axios.post("/ApplicatFriend/sendApplicatAddFriend", form).then((res) => {
                        if (res.data.status === 200) {
                            this.$message.success("好友请求已发送，等待对方回应");
                        } else {
                            this.$message.error("发送失败！");
                        }
                    })
                }
            })
        }
    },
    //钩子函数
    mounted() {
        this.getMyINfromation();
    }
}
</script>

<style scoped>
.top {
    background-color: #EAEAEA;
    display: flex;
    align-items: center;
    height: 50px;
}

.top p {
    font-size: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.search-box {
    display: flex;
    align-items: center;
    margin: 15px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 30px;
}

.search-box input {
    flex: 1;
    border: none;
    outline: none;
    margin-top: 10px;
}

.ContactList {
    background-color: aliceblue;
    display: flex;
    align-items: center;
    width: 100%;
    /* 盒子宽度100% */
    height: 4rem;
    /* 子元素水平分布 */
    position: relative;
}

.ContactList+.ContactList {
    margin-top: 0.1rem;
}

.ContactList img {
    height: 3rem;
    width: 3rem;
    margin-left: 0.8rem;
    margin-right: 1rem;
    border-radius: 7px;
}

.ContactList .nicheng {
    margin-top: 0px;
    flex: 1;
}
</style>