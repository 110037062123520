<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>群聊</p>
        </div>
        

        <div class="myCreatGroup">
            <div class="div1" @click="toShowOrCloseIcreatGroup">
                <img v-if="this.IcreatGroupShowStatus == 0" class="img1" src="../assets/右三角.png">
                <img v-if="this.IcreatGroupShowStatus == 1" class="img1" src="../assets/下三角.png">
                <p class="p1">我创建的群聊</p>
            </div>
            <div v-if="this.IcreatGroupShowStatus == 1" class="div2" v-for="g in this.IcreateGroupInformation" @click="toGroupChat(g.groupId)"> 
                <img :src="g.groupAvatar">
                <p>{{g.groupName}}</p>
            </div>
        </div>
        <div class="myJoinGroup">
            <div class="div1" @click="toShowOrCloseIJoinGroup">
                <img v-if="this.IJoinGroupShowStatus == 0" class="img1" src="../assets/右三角.png">
                <img v-if="this.IJoinGroupShowStatus == 1" class="img1" src="../assets/下三角.png">
                <p class="p1">我加入的群聊</p>
            </div>
            <div v-if="this.IJoinGroupShowStatus == 1" class="div2" v-for="g in this.IjoinGroupInformation" @click="toGroupChat(g.groupId)">
                <img :src="g.groupAvatar">
                <p>{{g.groupName}}</p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            IcreateGroupInformation: [], // 我创建的群聊
            IcreatGroupShowStatus: 0, //是否展示我创建的群聊
            IjoinGroupInformation: [], //我加入的群聊
            IJoinGroupShowStatus: 0, //是否展示我加入的群聊
        }
    },
    methods:{
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //获取Id
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        //点击展示/隐藏我创建的群聊
        toShowOrCloseIcreatGroup(){
            if(this.IcreatGroupShowStatus == 0){
                this.IcreatGroupShowStatus = 1;
            }else{
                this.IcreatGroupShowStatus = 0;
            }
            
        },
        //展示/隐藏我加入的群聊
        toShowOrCloseIJoinGroup(){
            if(this.IJoinGroupShowStatus == 0){
                this.IJoinGroupShowStatus = 1;
            }else{
                this.IJoinGroupShowStatus = 0
            }
        },
        //根据我的Id查询我创建的群聊
        getMyCreatGroup(){
            const params = new URLSearchParams;
            params.append('myId',this.myId);
            this.$axios.post("/Group/getMyCreatGroup",params).then((res)=>{
                this.IcreateGroupInformation = res.data.data;
                // console.log(res);
                // console.log(this.groupInformation);
            })
        },
        //根据我的Id查询我加入的群聊
        grtMyJoinGroup(){
            const params = new URLSearchParams;
            params.append('myId',this.myId);
            this.$axios.post("/Group/getMyJoinGroup",params).then((res)=>{
                this.IjoinGroupInformation = res.data.data;
                //console.log(this.IjoinGroupInformation);
            })
        },
        //点击跳转到群聊天页面，并将群号存储在浏览器缓存当中
        toGroupChat(groupId){
            localStorage.setItem('groupId',groupId);
            //console.log(localStorage.getItem('groupId'));
            this.$router.push('/groupChat');
        }
    },
    mounted(){
        this.getId();
        this.getMyCreatGroup();
        this.grtMyJoinGroup();
    }
}
</script>

<style>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.myCreatGroup{
    width: 100vw;
    background-color: white;
}
.myCreatGroup .div1{
    display: flex;
    width: 100vw;
    height: 30px;
    align-items: center;
}
.myCreatGroup .img1{
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
.myCreatGroup .p1{
    margin: 0 0 0 5px;
}
.myCreatGroup .div2{
    display: flex;
    align-items: center;
}
.myCreatGroup .div2+.div2{
    margin-top: 2px;
}
.myCreatGroup .div2 img{
    width: 40px;
    height: 40px;
    margin-left: 5px;
    border-radius: 5px;
}
.myCreatGroup .div2 p{
    margin: 0 0 0 5px;
    color: blue;
}

.myJoinGroup{
    width: 100vw;
    background-color: white;
}
.myJoinGroup .div1{
    display: flex;
    width: 100vw;
    height: 30px;
    align-items: center;
}
.myJoinGroup .img1{
    width: 20px;
    height: 20px;
    margin-left: 5px;
}
.myJoinGroup .p1{
    margin: 0 0 0 5px;
}
.myJoinGroup .div2{
    display: flex;
    align-items: center;
}
.myJoinGroup .div2+.div2{
    margin-top: 2px;
}
.myJoinGroup .div2 img{
    width: 40px;
    height: 40px;
    margin-left: 5px;
    border-radius: 5px;
}
.myJoinGroup .div2 p{
    margin: 0 0 0 5px;
    color: blue;
}

</style>