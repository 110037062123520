<template>
    <div class="settingBody">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>设置</p>
        </div>
        <div class="neirong" @click="toChangePassword">
            <p>修改密码</p>
            <div><img style="height: 20px; width: 20px; margin-right: 5px;" src="../assets/右箭头.png" alt=""></div>
        </div>
        <div class="tuichu">
            <!-- <button @click="equitLogin">退出登录</button> -->
            <el-popconfirm title="确定退出登录吗?" @confirm="equitLogin">
                <template #reference>
                    <el-button class="button1">退出登录</el-button>
                </template>
            </el-popconfirm>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //退出登录，跳转到登录界面
        equitLogin() {
            window.sessionStorage.removeItem('token');
            this.$router.replace('/newLogin');
        },
        //去修改密码界面
        toChangePassword() {
            //localStorage.clear();
            this.$router.push('/changePassword');
        }
    }
}
</script>
<style scoped>
.settingBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.tuichu {
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 20px;
    height: 45px;
    align-items: center;
}

.tuichu p {
    font-size: 20px;
}

.neirong {
    display: flex;
    align-items: center;
    background-color: white;
    height: 45px;
    border-radius: 10px;
}

.neirong p {
    font-size: 1rem;
    flex: 1;
    margin-left: 10px;
}

button {
    background-color: orangered;
    color: white;
    height: 35px;
    width: 10rem;
    border-radius: 8px;
    font-size: 18px;
    text-align: center;
}
</style>