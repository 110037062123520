<template>
    <router-view />
    <!-- <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view> -->
</template>

<script>
import { BASE_URL } from './url.js';
import { provide } from 'vue';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
export default {
  name: "App",
  data() {
    return {
      stompClient: null
    }
  },
  created() {
    this.connectWebSocket();
  },
  methods: {
    connectWebSocket() {
      const socket = new SockJS(BASE_URL + '/jiuxing/websocket'); // 修改为相应的服务器地址和端点
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect({}, this.onConnected, this.onError);
      provide('stompClient', this.stompClient); // 提供stompClient给子组件
    },
    onConnected() {
      console.log('WebSocket连接已建立');
    },
    onError(error) {
      console.error('WebSocket连接错误:', error);
    },
  },
};

// 解决窗口大小变化导致的报错
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}



</script>

<style lang="scss">
body {
  margin: 0 !important;
  overflow: hidden;
  //background: url(./assets/bodybj.jpg);
}

/* 超级炫酷的过渡动画 */
.fade-leave-active,
.fade-enter-active {
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.fade-enter-from {
  opacity: 0;
  transform: rotateY(90deg) scale(0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.fade-enter-to {
  opacity: 1;
  transform: rotateY(0deg) scale(1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
</style>
