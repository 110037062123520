<template>
    <div id="enrollBody">
        <!-- <div id="zhuceKuang">
            <div class="back"><img @click="goBack" src="../assets/ZS返回.png" alt=""></div>
            <div class="head">
                <h2>注册</h2>
            </div>
            <div class="touxiang"><img @click="selectFile" :src="selectedImage" alt=""></div>
            <div class="text">
                <label for="">账号:</label>
                <input type="text" id="userId" v-model="userId" placeholder="数字类型，不要超过九位!" required><br />
                <label for="">昵称:</label>
                <input type="text" id="userName" v-model="userName" required><br />
                <label for="">密码:</label>
                <input type="password" id="password" v-model="password" required><br />
                <input type="file" style="display: none;" accept="image/*" ref="fileInput" @change="onFileSelected">
            </div>
            <div class="zhuce">
                <el-button @click="enrollUser" type="primary" round>注册账号</el-button>
            </div>
        </div> -->

        <!-- 注册框 -->
        <div class="enroll">
            <p id="p1"><b>注册账号</b></p>
            <div id="div_input"><input v-model="userId" type="text" placeholder="请输入账号,数字类型,尽量小于九位"></div>
            <div id="div_input"><input v-model="userName" type="text" placeholder="设置网名"></div>
            <div id="div_input"><input v-model="password" type="password" placeholder="设置密码"></div>
            <div id="div_button">
                <button @click="goBack">取消</button>
                <button @click="enrollUser">确认注册</button>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    data() {
        return {
            userId: '',   //账号
            userName: '',  //昵称
            password: '',  //密码
            selectedFile: null, //设置一个变量来存储选择的文件
            selectedImage: require('../assets/头像.png'), // 默认头像图片路径
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //注册
        enrollUser() {
            // if (!this.selectedFile) {
            //     // 文件未选择，进行处理与提示
            //     console.log("请选择头像！！！");
            //     window.alert('请选择头像');
            // }
            const enrollList = new FormData;
            enrollList.append('userId', this.userId);
            enrollList.append('userName', this.userName);
            enrollList.append('password', this.password);
            //enrollList.append('avatarImg', this.selectedFile);


            //先判断账号是否已经被注册，如果已被注册则不能注册此账号
            const params = new URLSearchParams;
            params.append("userId", this.userId);
            this.$axios.post('/User/estimateUserIdIfHave', params).then((res) => {
                console.log(res);
                if (res.data.status == 1) {
                    this.$message.error("该账号已被注册，请换一个账号重试！");
                } else {
                    this.$axios.post('/User/enroll', enrollList).then((res) => {
                        console.log(res);
                        if (res.data.status == 200) {
                            this.$message.success('注册成功!');
                            this.$router.push('/newLogin');
                        } else {
                            this.$message.error('注册失败！');
                        }
                    })
                }
            })
        },
        //处理点击“发送图片”按钮的事件
        selectFile() {
            this.$refs.fileInput.click();
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中
        // onFileSelected(event) {
        //     this.selectedFile = event.target.files[0];
        //     if (this.selectedFile) {
        //         this.selectedImage = URL.createObjectURL(this.selectedFile);
        //     }
        // },
    },
}
</script>

<style scoped>
/* #enrollBody {
    background-image: url("../assets/ld.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
} */

/* #zhuceKuang {
    width: 75%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.back img {
    width: 30px;
    left: 10px;
    position: absolute;
    top: 10px;
} */

/* .touxiang {
    text-align: center;
} */

/* .touxiang img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid blueviolet;
    overflow: hidden;
    display: inline-block;
} */

/* .head {
    text-align: center;
}

.head h2 {
    font-size: 28px;
    color: white;
}

.text {
    text-align: center;
    padding: 10px;
}

.zhuce {
    text-align: center;
} */

#enrollBody {
    width: 100vw;
    height: 100vh;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enroll {
    background-color: white;
    width: 80vw;
    height: 285px;
    border-radius: 20px;
}

.enroll #p1 {
    font-size: 25px;
    text-align: center;
    height: 10%;
}

.enroll #div_input {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enroll input {
    width: 80%;
    height: 60%;
    border-radius: 20px;
    border-color: cornflowerblue;
}

#div_button {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enroll button {
    height: 50%;
    width: 30%;
    background-color: white;
    border-radius: 30px;
    font-size: 15px;
    margin: 10px;
    border-color: cornflowerblue;
}
</style>
