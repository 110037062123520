<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>动态详情</p>
        </div>
        <!-- 大图片 -->
        <div class="bigImgFu" @click="closeBigImg">
            <img v-if="bigImgSrc" class="bigImg" :src="bigImgSrc">
        </div>
        <div class="card">
            <!-- 头部信息 -->
            <div class="card_top">
                <img :src="this.dynamicList.avatar">
                <p class="p1">{{ this.dynamicList.userName }}</p>
                <p @click="openDeleteVisBale" style="font-size: 14px;color: blue;margin-right: 20px;"
                    v-if="this.userId == this.myId">删除</p>
            </div>
            <!-- 动态文字部分 -->
            <div class="card_text">
                <p>{{ this.dynamicList.content }}</p>
            </div>
            <!-- 动态图片部分 -->
            <div class="card_img">
                <img :src="img" v-for="img in this.dynamicList.imageList" @click="getBigImgSrc(img)">
            </div>
            <div style="display: flex;">
                <div style="flex: 1;"></div>
                <p @click="openReviewV1" style="margin-right: 20px;color: blue;font-size: 13px;">写评论</p>
            </div>
            <!-- 点赞好友的头像 -->
            <div class="likeUserAvatar">
                <img v-if="this.likeUserList != ''" src="../assets/cp喜欢.png" style="width: 20px;height: 20px;">
                <img :src="l.avatar" v-for="l in this.likeUserList">
            </div>
            <!-- 评论区 -->
            <hr />
            <div class="review" v-for="r in this.reviewInformation">
                <div class="review_top">
                    <img :src="r.avatar">
                    <p class="p1">{{ r.nickName }}</p>
                    <p class="p2">{{ r.handTime }}</p>
                </div>

                <div class="reviewContent">
                    <p @click="openReviewV2(r)" style="flex: 1;">{{ r.reviewContent }}</p>
                    <p @click="getReview2(r.id)"
                        v-if="r.replyCount > 0 && !(this.review2.length > 0 && this.review2[0].belongId == r.id)"
                        class="p2">展开回复</p>
                    <p @click="closeReview2"
                        v-else-if="r.replyCount > 0 && this.review2.length > 0 && this.review2[0].belongId == r.id"
                        class="p2">收起回复</p>
                </div>
                <div v-if="r.replyCount > 0" v-for="r2 in this.review2" class="huifuReview">
                    <p @click="openReviewV3(r2)" v-if="r2.belongId == r.id" class="p1">{{ r2.userName }} 回复 {{
                r2.fatherName
            }}：{{ r2.reviewContent }}
                    </p>
                    <!-- 三级评论框 -->
                    <div v-if="this.reviewV3 == 1" class="whiteReview">
                        <input type="text" v-model="this.whiteReviewContent3" placeholder="回复评论..." />
                        <div><button @click="whiteReview3">发送</button></div>
                    </div>
                </div>
                <hr />
                <!-- 二级评论框 -->
                <div v-if="this.reviewV2 == 1" class="whiteReview">
                    <input type="text" v-model="this.whiteReviewContent2" placeholder="回复评论..." />
                    <div><button @click="whiteReview2">发送</button></div>
                </div>

            </div>
            <!-- 写评论 -->
            <!-- 一级评论框 -->
            <div v-if="this.reviewV1 == 1" class="whiteReview">
                <input type="text" v-model="this.whiteReviewContent" placeholder="我要评论..." />
                <div><button @click="sendReview">发送</button></div>
            </div>
        </div>

        <!-- 确认删除的模态框 -->
        <div class="mtkBody" v-if="this.dedleteVisBale">
            <div class="motaikuang">
                <div class="motaikuang_top">
                    <p class="p1">提示</p>
                    <p class="p2">删除该动态?</p>
                </div>
                <div class="motaikuang_bottom">
                    <button class="button1" @click="closeDeleteVisBale">取消</button>
                    <button class="button2" @click="deleteDynamicByDynamicId">删除</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dynamicId: null, //动态Id
            userId: null,
            myId: null,
            dynamicList: {
                avatar: null,
                userName: null,
                content: null,
                publishTime: null,
                imageList: []
            }, //动态信息
            likeUserList: [], //点赞列表
            bigImgSrc: null,
            reviewInformation: [],
            whiteReviewContent: null, //一级评论框的内容
            review2: [], //二级回复
            reviewV1: 0, //回复输入框1
            reviewV2: 0,//回复输入框2(二级评论)
            reviewV3: 0,//三级评论输入框
            whiteReviewContent2: null, //二级评论框的内容
            whiteReviewContent3: null,//三级评论框的内容
            whiteReview2Body: {
                handerUserId: null,
                dynamicId: null,
                reviewContent: null,
                fatherReviewId: null,
                belongId: null,
                userId2: null,  //给这个人发送Websocket消息
            },
            whiteReview3Body: {
                handerUserId: null,
                dynamicId: null,
                reviewContent: null,
                fatherReviewId: null,
                belongId: null,
                userId2: null,  //给这个人发送Websocket消息
            },
            dedleteVisBale : false  //确定删除的模态框
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //获取动态Id
        getId() {
            this.dynamicId = localStorage.getItem('dynamicId');
            this.userId = localStorage.getItem('DynamicUserId');
            this.myId = localStorage.getItem('userId');
            console.log('动态Id:' + this.dynamicId);
            console.log('userId:' + this.userId);
        },
        //获取头像昵称等
        getUserInformation() {
            this.$axios.get('/User/getUserInformation/' + this.userId).then((res) => {
                this.dynamicList.userName = res.data.data[0].userName;
                this.dynamicList.avatar = res.data.data[0].avatar;
                //console.log(this.dynamicList);
            })
        },
        //获取动态信息
        getDynamicInformation() {
            const params = new URLSearchParams;
            params.append('dynamicId', this.dynamicId);
            this.$axios.post('/Dynamic/getDynamicById', params).then((res) => {
                console.log(res);
                this.dynamicList.content = res.data.data.content;
                this.dynamicList.publishTime = res.data.data.publishTime;
                this.dynamicList.imageList = JSON.parse(res.data.data.imageList);
            })

        },
        //获取点赞列表
        getLikeUserList() {
            const params = new URLSearchParams;
            params.append('dynamicId', this.dynamicId);
            this.$axios.post('/Dynamic/getLikeUserByDynamicId', params).then((res) => {
                this.likeUserList = res.data.data;
            })
        },
        //点击图片查看大图（获取到相应的链接）
        getBigImgSrc(img) {
            localStorage.setItem('imgPath', img);
            this.$router.push('/lookBigImg');
        },
        //点击大图关闭预览
        closeBigImg() {
            this.bigImgSrc = null;
        },
        //获取评论内容
        getReviewInformation() {
            var body = {
                dynamicId: this.dynamicId,
                userId: this.myId
            }
            this.$axios.post("/Dynamic/getReviewByDynamicIDAndUserId", body).then((res) => {
                this.reviewInformation = res.data.data;
                console.log(this.reviewInformation);
            })
        },
        //打开评论框1
        openReviewV1() {
            this.reviewV1 = 1;
        },
        //写二级评论
        whiteReview2(r) {
            // var body = {
            //     handerUserId: this.myId,
            //     dynamicId: this.dynamicId,
            //     reviewContent: this.whiteReviewContent2,
            //     fatherReviewId: r.id,
            //     belongId: r.id
            // }
            this.whiteReview2Body.reviewContent = this.whiteReviewContent2;
            console.log("whiteReview2Body:" + this.whiteReview2Body);
            this.$axios.post("/Dynamic/whiteReview2", this.whiteReview2Body).then((res) => {
                console.log(res);
                this.getDynamicInformation();
                this.getReviewInformation();
                this.review2 = [];
                this.whiteReviewContent2 = null;
                this.reviewV2 = 0;
            })
        },
        //发布评论
        sendReview() {
            var body = {
                myId: this.myId,
                dynamicId: this.dynamicId,
                reviewContent: this.whiteReviewContent
            }
            this.$axios.post("/Dynamic/whiteReview", body).then((res) => {
                //console.log(res);
                if (res.data.status == 200) {
                    this.$message.success("发布成功！");
                } else {
                    this.$message.error("发布失败！");
                }
                this.getReviewInformation();
                this.whiteReviewContent = null;
                this.reviewV1 = 0;
            })
        },
        //获取该条评论下的回复
        getReview2(id) {
            const params = new URLSearchParams;
            params.append('belongId', id);
            this.$axios.post("/Dynamic/getReview2", params).then((res) => {
                this.review2 = res.data.data;
                console.log(this.review2);
            })
        },
        //收起回复
        closeReview2() {
            this.review2 = [];
        },
        //打开评论框2
        openReviewV2(r) {
            this.reviewV1 = 0;
            this.reviewV3 = 0;
            this.whiteReview2Body.handerUserId = this.myId;
            this.whiteReview2Body.dynamicId = this.dynamicId;
            this.whiteReview2Body.fatherReviewId = r.id;
            this.whiteReview2Body.belongId = r.id;
            this.whiteReview2Body.userId2 = r.handerUserId;
            this.reviewV2 = 1;
        },
        //打开三级评论框
        openReviewV3(r2) {
            this.reviewV1 = 0;
            this.reviewV2 = 0;
            this.whiteReview3Body.handerUserId = this.myId;
            this.whiteReview3Body.dynamicId = this.dynamicId;
            this.whiteReview3Body.fatherReviewId = r2.id;
            this.whiteReview3Body.belongId = r2.belongId;
            this.whiteReview3Body.userId2 = r2.handerUserId;
            this.reviewV3 = 1;
        },
        //写三级评论
        whiteReview3() {
            this.whiteReview3Body.reviewContent = this.whiteReviewContent3;
            this.$axios.post("/Dynamic/whiteReview2", this.whiteReview3Body).then((res) => {
                console.log(res);
                this.getDynamicInformation();
                this.getReviewInformation();
                this.review2 = [];
                this.whiteReviewContent3 = null;
                this.reviewV3 = 0;
            })
        },
        //删除动态的方法
        deleteDynamicByDynamicId() {
            const params = new URLSearchParams;
            params.append('dynamicId', this.dynamicId);
            this.$axios.post("/Dynamic/deleteDynamicByDynamicId", params).then((res) => {
                if (res.data.status == 200) {
                    this.$message.success("删除成功");
                    this.goBack();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        //打开确定删除动态的模态框
        openDeleteVisBale(){
            this.dedleteVisBale = true;
        },
        //关闭确定删除动态的模态框
        closeDeleteVisBale(){
            this.dedleteVisBale = false;
        }
    },
    mounted() {
        this.getId();
        this.getUserInformation();
        this.getDynamicInformation();
        this.getLikeUserList();
        this.getReviewInformation();
    }
}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    overflow: auto;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.card {
    height: calc(100vh - 100px);
    overflow-y: auto;
}

.card .card_top {
    display: flex;
    align-items: center;
}

.card .card_top img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-top: 5px;
    border-radius: 8px;
    object-fit: cover;
}

.card .card_top .p1 {
    margin-left: 20px;
    font-size: 17px;
    flex: 1;
}

.card .card_top .p2 {
    color: gray;
    font-size: 12px;
    margin-right: 20px;
}

.card .card_text p {
    margin-left: 15px;
    margin-right: 15px;
}

.card .card_img {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.card .card_img img {
    width: 32%;
    margin-bottom: 3px;
    object-fit: contain;
}

.likeUserAvatar {
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    align-items: center;
}

.likeUserAvatar img {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    object-fit: cover;
}

.bigImg {
    width: 100vw;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    background-color: black;
    height: 100vh;
    object-fit: contain;
}

hr {
    width: 95vw;
    margin-top: 10px;
    border-color: currentColor;
    border-block-start-style: none;
}

.card .review_top {
    display: flex;
    align-items: center;
}

.card .review_top img {
    width: 35px;
    height: 35px;
    margin-left: 15px;
    margin-top: 5px;
    border-radius: 8px;
    object-fit: cover;
}

.card .review_top .p1 {
    margin-left: 10px;
    font-size: 13px;
    flex: 1;
    color: royalblue;
}

.card .review_top .p2 {
    color: gray;
    font-size: 12px;
    margin-right: 20px;
}

.reviewContent {
    margin-left: 58px;
    margin-top: -23px;
    display: flex;
}

.reviewContent .p2 {
    font-size: 12px;
    color: blue;
    margin-right: 20px;
    margin-top: 25px;
}

.huifuReview {
    padding: 0px 0px 5px 0px;
}

.huifuReview .p1 {
    margin: 0;
    margin-left: 57px;
    font-size: 13px;
}

.whiteReview {
    position: absolute;
    bottom: 2px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whiteReview input {
    flex: 1;
    height: 35px;
    outline: none;
    padding: 0 0 0 10px;
    font-size: 14px;
    width: 80vw;
    margin-left: 5px;
}

.whiteReview button {
    background-color: deepskyblue;
    color: white;
    border: none;
    outline: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.mtkBody {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.motaikuang {
    width: 85vw;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%);
}
.motaikuang .motaikuang_top{
    height: 80px;
    text-align: center;
}
.motaikuang .motaikuang_top .p1{
    font-weight: bold;
    margin-top: 20px;
    font-size: 20px;
}
.motaikuang .motaikuang_top .p2{
    color: darkslategray;
}

.motaikuang .motaikuang_bottom{
    width: 85vw;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center
}
.motaikuang .motaikuang_bottom .button1{
    height: 30px;
    width: 60px;
    border: none;
    background: lightgray;
    border-radius: 6px;
}
.motaikuang .motaikuang_bottom .button2{
    height: 30px;
    width: 60px;
    border: none;
    background: dodgerblue;
    border-radius: 6px;
    color: wheat;
}
</style>