<template>
    <div>
        <div class="prop">
            <img src="https://tse1-mm.cn.bing.net/th/id/OIP-C.Zte3ljd4g6kqrWWyg-8fhAHaEo?w=294&h=183&c=7&r=0&o=5&dpr=1.3&pid=1.7">
            <p>这是一张图片</p>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
.prop{
    width: 100%;
    height: 15rem;
    background-color: aqua;
    position: relative;
}
img{
    height: 15rem;
    width: 100%;
}
p{
    position: absolute;
    bottom: 2px;
    left: 4px;
    color: aliceblue;
}
</style>