<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>创建群聊</p>
        </div>
        <div class="friendList">
            <div class="friendInformation" v-for="f in this.FriendShipList">
                <input type="checkbox" v-model="this.checkedFriendsItems" :value="f.userId2">
                <img :src="f.avatar">
                <p>{{f.nickName}}</p>
            </div>
        </div>
        <div class="bottom">
            <button @click="OK">确定创建</button>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            myId: null,
            checkedFriendsItems: [],
            FriendShipList: []
        }
    },
    methods:{
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId(){
            this.myId = parseInt(localStorage.getItem('userId'),10);
        },
        //获取好友列表
        getFriendListByMyId() {
            this.$axios.get('/FriendShip/getFriendListByUserId1/' + this.myId).then((res) => {
                this.FriendShipList = res.data.data;
                //console.log(this.FriendShipList);
            })
        },
        //确定创建的事件
        OK(){
            var body = {
                myId : this.myId,
                userIdList : this.checkedFriendsItems
            }
            this.$axios.post("/Group/createGroup",body).then((res)=>{
                if(res.data.status == 200){
                    this.$message.success("创建成功！");
                    this.goBack();
                }
            })
        }
    },
    mounted(){
        this.getId();
        this.getFriendListByMyId();
    }
}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}
.friendList{
    width: 100vw;
    height: calc(100vh - 100px);
    overflow: auto;
}
.friendInformation+.friendInformation{
    margin-top: 1px;
}
.friendInformation{
    display: flex;
    width: 100vw;
    background-color: white;
    align-items: center;
}
.friendInformation input{
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-right: 10px;
}
.friendInformation img{
    width: 45px;
    height: 45px;
    border-radius: 8px;
}
.friendInformation p{
    margin-left: 15px;
    font-weight: bold;
}
.bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aquamarine;
    height: 45px;
}
.bottom button{
    padding: 4px;
    background: deepskyblue;
    color: white;
    border-radius: 8px;
}
</style>