<template>
    <img class="cha" src="../assets/叉叉.png" @click="goBack">
    <div ref="imageContainer" class="image-container" @touchstart.prevent="onTouchStart"
        @touchmove.prevent="onTouchMove" @touchend.prevent="onTouchEnd">
        <img ref="image" class="zoomable-image" :src="imgPath"
            :style="{ transform: 'scale(' + scale + ') translate(' + translateX + 'px, ' + translateY + 'px)' }" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgPath: null,
            touchStartDistance: 0,
            touchMoveDistance: 0,
            scale: 1,
            lastTouch: { x: 0, y: 0 },
            translateX: 0,
            translateY: 0
        };
    },
    methods: {
        getimgPath() {
            this.imgPath = localStorage.getItem('imgPath');
        },
        goBack() {
            this.$router.back();
        },
        onTouchStart(event) {
            if (event.touches.length === 1) {
                this.lastTouch.x = event.touches[0].clientX;
                this.lastTouch.y = event.touches[0].clientY;
            } else if (event.touches.length === 2) {
                this.touchStartDistance = Math.hypot(
                    event.touches[1].clientX - event.touches[0].clientX,
                    event.touches[1].clientY - event.touches[0].clientY
                );
            }
        },
        onTouchMove(event) {
            if (event.touches.length === 1) {
                const deltaX = (event.touches[0].clientX - this.lastTouch.x) * 0.5;
                const deltaY = (event.touches[0].clientY - this.lastTouch.y) * 0.5;
                this.translateX += deltaX;
                this.translateY += deltaY;

                this.lastTouch.x = event.touches[0].clientX;
                this.lastTouch.y = event.touches[0].clientY;
            } else if (event.touches.length === 2) {
                this.touchMoveDistance = Math.hypot(
                    event.touches[1].clientX - event.touches[0].clientX,
                    event.touches[1].clientY - event.touches[0].clientY
                );
                this.scale = this.touchMoveDistance / this.touchStartDistance * 3;
            }
        },
        onTouchEnd() {
            this.touchStartDistance = 0;
            this.touchMoveDistance = 0;
            this.lastTouch = { x: 0, y: 0 };
        }
    },
    mounted() {
        this.getimgPath();
    }
};
</script>

<style>
.image-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.zoomable-image {
    width: 100vw;
    height: auto;
    transition: transform 0.1s ease;
}
.cha{
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
}
</style>