<template>
  <div class="Fu">
    <div class="routerView"><router-view></router-view></div>
    <div class="daoHang">
      <div @click="JumpVX">
        <span v-if="this.IsRead0Sum > 0" class="jiaobiao">{{ this.IsRead0Sum }}</span>
        <img :src="currentRoute === '/chatList' ? require('../assets/消息01.png') : require('../assets/消息00.png')">
        <p :class="{ blueText: currentRoute === '/chatList' }">消息</p>
      </div>
      <div @click="JumpGroupChatList">
        <span v-if="this.groupMessageRead0Sum > 0" class="jiaobiao">{{ this.groupMessageRead0Sum }}</span>
        <img :src="currentRoute === '/groupChatList' ? require('../assets/群消息04.png') : require('../assets/群消息03.png')">
        <p :class="{ blueText: currentRoute === '/groupChatList' }">群消息</p>
      </div>
      <div @click="JumpTXL">
        <span v-if="this.ApplicatFriendToMeStatus0Count > 0" class="jiaobiao">{{ this.ApplicatFriendToMeStatus0Count
          }}</span>
        <img :src="currentRoute === '/txl' ? require('../assets/通讯录01.png') : require('../assets/通讯录00.png')">
        <p :class="{ blueText: currentRoute === '/txl' }">通讯录</p>
      </div>
      <div @click="JumpFX">
        <span v-if="this.read0Sum > 0" class="jiaobiao">{{ this.read0Sum }}</span>
        <img :src="currentRoute === '/dynamicSpace' ? require('../assets/发现01.png') : require('../assets/发现00.png')">
        <p :class="{ blueText: currentRoute === '/dynamicSpace' }">动态</p>
      </div>
      <div @click="JumpME">
        <img :src="currentRoute === '/myView' ? require('../assets/我01.png') : require('../assets/我00.png')">
        <p :class="{ blueText: currentRoute === '/myView' }">我</p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import Stomp from 'stompjs';
export default {
  data() {
    return {
      myId: null,
      currentRoute: '',   //当前路由,
      ApplicatFriendToMeStatus0Count: null, //待处理的好友申请数
      IsRead0Sum: null, //消息未读总数（聊天）
      readView0Count: null, //未读评论数
      readLike0Count: null, //未读点赞数
      read0Sum: null, //未读消息总数(动态)
      groupMessageRead0Sum: null, //消息未读总数（群消息）
      groupIdList: [], //放的是所有包含我的群的群Id
    };
  },
  methods: {
    JumpVX() {
      this.$router.replace('/chatList');
      setTimeout(() => {
        this.getRouterPath();
      }, 100); // 延迟100毫秒执行获取路由地址的操作
    },
    JumpTXL() {
      this.$router.replace('/txl');
      setTimeout(() => {
        this.getRouterPath();
      }, 100);
    },
    JumpFX() {
      this.$router.replace('/dynamicSpace');
      setTimeout(() => {
        this.getRouterPath();
      }, 100);
    },
    JumpME() {
      this.$router.replace('/myView');
      setTimeout(() => {
        this.getRouterPath();
      }, 100);
    },
    JumpGroupChatList() {
      this.$router.replace('/groupChatList');
      setTimeout(() => {
        this.getRouterPath();
      }, 100);
    },
    getRouterPath() {
      console.log(this.$route.path); // 输出当前路由的路径
      this.currentRoute = this.$route.path;
    },
    getId() {
      this.myId = parseInt(localStorage.getItem('userId'), 10);
    },
    //查询我收到的好友申请中是否有待处理
    getApplicatFriendToMeStatus0Count() {
      this.$axios.get("/ApplicatFriend/getApplicatFriendToMeStatus0Count/" + this.myId).then((res) => {
        this.ApplicatFriendToMeStatus0Count = res.data.data;
        console.log(this.ApplicatFriendToMeStatus0Count);
      })
    },
    //获取个人消息未读总数
    getIsRead0Sum() {
      this.$axios.get("/Message/getIsRead0Sum/" + this.myId).then((res) => {
        //console.log(res);
        this.IsRead0Sum = res.data.data;
        //console.log("获取了一次未读消息数");
      })
    },
    getgroupIdList() {
      const params = new URLSearchParams;
      params.append('myId', this.myId);
      this.$axios.post("/Group/getIJoinGroupIdByMyId", params).then((res) => {
        this.groupIdList = res.data.data;
        console.log(this.groupIdList);
      })
    },
    //获取群消息未读总数
    getGroupMessageRead0Sum() {
      const params = new URLSearchParams;
      params.append('myId', this.myId);
      this.$axios.post("/ReadCardGroupMessage/getRead0ByMyId", params).then((res) => {
        this.groupMessageRead0Sum = res.data.data;
        //console.log(this.groupMessageRead0Sum);
      })
    },
    // getReadView0Count() {
    //   const params = new URLSearchParams;
    //   params.append('userId', this.myId);
    //   this.$axios.post("/Dynamic/getReadView0", params).then((res) => {
    //     this.readView0Count = res.data.data;
    //     //console.log("readView0Count="+this.readView0Count);
    //     //console.log("获取未读评论数");
    //   })
    // },
    // getReadLike0Count() {
    //   const params = new URLSearchParams;
    //   params.append('userId', this.myId);
    //   this.$axios.post("/Dynamic/getReadLike0", params).then((res) => {
    //     this.readLike0Count = res.data.data;
    //     //console.log("readLike0Count="+this.readLike0Count);
    //     //console.log("获取未读消息数");
    //   })
    // },
    //获取未读消息总数（动态）
    getRead0Sum() {
      const params = new URLSearchParams;
      params.append('userId', this.myId);
      this.$axios.post("/Dynamic/getAboveMeRead0Sum", params).then((res) => {
        this.read0Sum = res.data.data;
        console.log("未读消息总数：" + this.read0Sum);
      })
    },
    //stomp的建立部分：
    // connectWebSocket() {
    //   const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
    //   this.stompClient = Stomp.over(socket);
    //   this.stompClient.connect({}, this.onConnected, this.onError);
    // },
    // onConnected() {
    //   console.log('WebSocket连接已建立');
    //   // 在连接成功后，可以订阅目标主题
    //   this.stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);  //订阅我的专属地址
    //   this.groupIdList.forEach(element => {
    //     //console.log(element);
    //     this.stompClient.subscribe('/topic/messages/' + element, this.onMessageReceived);  //订阅我的专属地址
    //   });
    // },
    // onError(error) {
    //   console.error('WebSocket连接错误:', error);
    // },
    // onMessageReceived(message) {
    //   console.log('收到消息:', message.body);
    //   // 处理接收到的消息
    //   this.getApplicatFriendToMeStatus0Count();
    //   this.getIsRead0Sum();
    //   this.getRead0Sum();
    //   this.getGroupMessageRead0Sum();
    // },
    onMessageReceived(message) {
      console.log('Navigation收到消息:', message.body);
      // 处理接收到的消息
      this.getApplicatFriendToMeStatus0Count();
      this.getIsRead0Sum();
      this.getRead0Sum();
      this.getGroupMessageRead0Sum();
    },
  },

  created() {
    this.getRouterPath();
    this.getId();
    //await this.connectWebSocket();
    this.getgroupIdList();
    this.getApplicatFriendToMeStatus0Count();
    this.getIsRead0Sum();
    this.getRead0Sum();
    this.getGroupMessageRead0Sum();
  },
  mounted(){
    const stompClient = inject('stompClient');
    setTimeout(() => {
      if (stompClient) {
        stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);
        for (const element of this.groupIdList) {
          console.log(element);
          stompClient.subscribe('/topic/messages/' + element, this.onMessageReceived);
        }
        console.log("Navigation.vue连接成功");
      } else {
        console.warn("没连上");
      }
    }, 500);
  },
  watch: {
    currentRoute() {
      this.getIsRead0Sum();
    }
  }
};
</script>

<style scoped>
.Fu {
  width: 100%;
  height: 100%;
}

.daoHang {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.3rem;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: space-around;
  background-color: white;
}

.daoHang div {
  width: 20%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

p {
  font-size: 13px;
  text-align: center;
  margin: 10px 0 0 0;
  position: absolute;
  margin-top: 48px;
}

img {
  width: 25px;
  height: 25px;
}

.routerView {
  width: 100%;
  height: calc(100% - 10vh);
  overflow: auto;
}

.blueText {
  color: blue;
  font-size: 13px;
  text-align: center;
  margin: 10px 0 0 0;
  position: absolute;
  margin-top: 48px;
}

.jiaobiao {
  background-color: red;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  top: 15px;
  font-size: 12px;
}
</style>