<!-- 登录页面 -->
<template>
  <div>
    <el-form :model="form" label-width="120px">
        <el-form-item label="登录名">
            <el-input v-model="form.loginName" />
        </el-form-item>
        <el-form-item label="密码">
            <el-input v-model="form.password"  type="password"/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="login">登录</el-button>
            <el-button>取消</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: 'LoginView',
    data() {
        return {
            form: {
                loginName: '',
                password: ''
            }
        };
    },
    methods: {
        login() {
            this.$http.post("/demo/admin/login", this.form).then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                    this.$message.success(res.data.message);
                } else {
                    this.$message.error(res.data.message);
                }
            })
        }
    }
}

</script>

<style lang="scss">
</style>