<template>
  <div>
    666
    <button @click="sendWebsocket">发送消息</button>
  </div>
</template>

<script>
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import Stomp from 'stompjs';
export default {
  data() {
    return {
      stompClient: null
    };
  },
  created() {
    this.connectWebSocket();
  },
  methods: {
    connectWebSocket() {
      const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect({}, this.onConnected, this.onError);
    },
    onConnected() {
      console.log('WebSocket连接已建立');
      // 在连接成功后，可以订阅目标主题
      this.stompClient.subscribe('/topic/messages/100002', this.onMessageReceived);
    },
    onError(error) {
      console.error('WebSocket连接错误:', error);
    },
    onMessageReceived(message) {
      console.log('收到消息:', message.body);
      // 处理接收到的消息
    },
    //调用后端接口，发送websocket消息给前端
    sendWebsocket(){
      this.$axios.post('/send').then((res)=>{
        console.log(res);
      })
    }
  }
};
</script>