<template>
    <div>
        <div class="top">
            <img @click="goBack" src="../assets/返回2.png" alt="">
            <p><b>更改昵称</b></p>
        </div>
        <div class="updateName">
            <input v-model="changeUserName" type="text" >
            <el-button @click="changeUserNameByUserId" type="primary" round>保存</el-button>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            myMain: {
                myId: null,
                myAvatar: null,
                myName: null
            },
            changeUserName: null, //待修改昵称
        }
    },
    methods:{
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //获取我的账号信息
        getMyMain() {
            let MyuserId = parseInt(localStorage.getItem('userId'), 10);
            //获取信息
            this.$axios.get('/User/getUserInformation/' + MyuserId).then((res) => {
                console.log(res);
                this.myMain.myId = res.data.data[0].userId;
                this.myMain.myName = res.data.data[0].userName;
                this.myMain.myAvatar = res.data.data[0].avatar;
                this.changeUserName=res.data.data[0].userName;
            })
        },
        //修改昵称的方法
        changeUserNameByUserId(){
            const params = new URLSearchParams();
            params.append("userId",this.myMain.myId);
            params.append("userName",this.changeUserName);
            this.$axios.post("/User/changeUserNameByUserId",params).then((res)=>{
                console.log(res);
                if(res.data.status==1){
                    this.$message.success("修改成功！");
                }else{
                    this.$message.success("修改失败！");
                }
            })
        }
    },
    created() {
        this.getMyMain();
    }
}
</script>
<style scoped>
.top {
    background-color: #ccc;
    display: flex;
    align-items: center;
    height: 55px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 23px;
}

.top img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.updateName {
    display: flex;
    align-items: center;
    margin: 15px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 30px;
}

.updateName input {
    flex: 1;
    border: none;
    outline: none;
    margin-top: 10px;
}
</style>