<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>发表动态</p>
            <el-button @click="publishDynamicOnlyContent" style="position: absolute;right: 15px;" size="small" type="primary">发布</el-button>
        </div>
        <div class="textPart">
            <el-input v-model="this.content" :rows="5" type="textarea" placeholder="文字内容..." />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            content: null,
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        //发布
        publishDynamicOnlyContent(){
            const params = new URLSearchParams();
            params.append("userId",this.myId);
            params.append("content",this.content);
            this.$axios.post("/Dynamic/publishDynamicOnlyContent",params).then((res)=>{
                if(res.data.status == 200){
                    this.$message.success("发布成功");
                    this.$router.replace('/dynamicSpace');
                }else{
                    this.$message.error("发布失败");
                }
            })
            
        }
    },
    created() {
        this.getId();
    }
}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}
</style>