<template>
    <div class="body">
        <div class="part1">
            <img src="../assets/APPLogo.png">
        </div>
        <div class="part2">
            <div class="inputKuang">
                <img src="../assets/账号.png" alt="">
                <span class="fgx">|</span>
                <input v-model="this.userId" type="text" placeholder="设置账号（数字类型，尽量小于9位）">
            </div>
            <div class="inputKuang">
                <img src="../assets/昵称.png" alt="">
                <span class="fgx">|</span>
                <input v-model="this.userName" type="text" placeholder="设置网名">
            </div>
            <div class="inputKuang">
                <img src="../assets/锁.png" alt="">
                <span class="fgx">|</span>
                <input v-model="this.password" type="password" placeholder="设置密码">
            </div>
            <div class="div1">
                <span class="span1">取消注册</span><span @click="goBack" class="span2">返回</span>
            </div>
        </div>

        <div class="part3">
            <div class="div1" @click="enrollUser">
                <p>注 册</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userId: '',   //账号
            userName: '',  //昵称
            password: '',  //密码
            selectedFile: null, //设置一个变量来存储选择的文件
            selectedImage: require('../assets/头像.png'), // 默认头像图片路径
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //注册
        enrollUser() {
            // if (!this.selectedFile) {
            //     // 文件未选择，进行处理与提示
            //     console.log("请选择头像！！！");
            //     window.alert('请选择头像');
            // }
            const enrollList = new FormData;
            enrollList.append('userId', this.userId);
            enrollList.append('userName', this.userName);
            enrollList.append('password', this.password);
            //enrollList.append('avatarImg', this.selectedFile);


            //先判断账号是否已经被注册，如果已被注册则不能注册此账号
            const params = new URLSearchParams;
            params.append("userId", this.userId);
            this.$axios.post('/User/estimateUserIdIfHave', params).then((res) => {
                console.log(res);
                if (res.data.status == 1) {
                    this.$message.error("该账号已被注册，请换一个账号重试！");
                } else {
                    this.$axios.post('/User/enroll', enrollList).then((res) => {
                        console.log(res);
                        if (res.data.status == 200) {
                            this.$message.success('注册成功!');
                            this.$router.replace('/newLogin');
                        } else {
                            this.$message.error('注册失败！');
                        }
                    })
                }
            })
        },
        //处理点击“发送图片”按钮的事件
        selectFile() {
            this.$refs.fileInput.click();
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中
        // onFileSelected(event) {
        //     this.selectedFile = event.target.files[0];
        //     if (this.selectedFile) {
        //         this.selectedImage = URL.createObjectURL(this.selectedFile);
        //     }
        // },
    },
}
</script>


<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.part1 {
    width: 100vw;
    height: 30vh;
    display: flex;
    align-items: end;
    justify-content: center;
}

.part1 img {
    width: 85px;
    height: 85px;
}

.part2 {
    width: 100vw;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.part2 .inputKuang {
    width: 85vw;
    height: 33px;
    background-color: #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
}



.inputKuang img {
    width: 20px;
    height: 20px;
    margin-left: 15px;
}

.inputKuang .fgx {
    margin-left: 6px;
    font-size: 18px;
    color: gray;
    font-weight: 100;
    margin-bottom: 3px;
}

.inputKuang input {
    margin-left: 10px;
    border: none;
    height: 22px;
    background-color: #efefef;
    outline: none;
    flex: 1;
}

.inputKuang+.inputKuang {
    margin-top: 10px;
}

.part2 .div1 {
    display: flex;
    width: 85vw;
    margin-left: 10px;
    margin-top: 10px;
}

.part2 .div1 .span1 {
    font-size: 13px;
    color: gray;
}

.part2 .div1 .span2 {
    font-size: 13px;
    color: red;
    margin-left: 10px;
}

.part3 {
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
}

.part3 .div1 {
    width: 85vw;
    background-color: dodgerblue;
    height: 35px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.part3 .div1 p {
    color: white;
}
</style>