import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "../views/LoginView.vue"
import homeView from "../views/homeView.vue"
import myView from "../views/MyView.vue"
import Navigation from '../views/Navigation.vue'
import testEcharts from '../views/testEcharts.vue'
import chat from '../views/chat.vue'
import chatList from '../views/chatList.vue'
import WechatLogin from '../views/WechatLogin.vue'
import txl from '../views/txl.vue'
import enroll from '../views/enroll.vue'
import addFriend from '../views/addFriend.vue'
import testWebsocket from '../views/testWebsocket.vue'
import myMain from '../views/myMain.vue'
import setting from '../views/setting.vue'
import changeUserName from '../views/changeUserName.vue'
import friendInformation from '../views/friendInformation.vue'
import settingRemark from '../views/settingRemark.vue'
import changePassword from '../views/changePassword.vue'
import newFriend from '../views/newFriend.vue'
import ISendApplicatFriend from '../views/ISendApplicatFriend.vue'
import chatSetting from '../views/chatSetting.vue'
import dynamicSpace from '../views/dynamicSpace.vue'
import publishDynamic from '../views/publishDynamic.vue'
import publishDynamicOnlyText from '../views/publishDynamicOnlyText.vue'
import dynamicDetails from '../views/dynamicDetails.vue'
import dynamicInteraction from '../views/dynamicInteraction.vue'
import reviewDetails from '../views/reviewDetails.vue'
import vidio from '../views/vidio.vue'
import manageChatHistory from '../views/manageChatHistory.vue'
import group from '../views/group.vue'
import groupChat from '../views/groupChat.vue'
import groupChatList from '../views/groupChatList.vue'
import createGroup from '../views/createGroup.vue'
import groupInformation from '../views/groupInformation.vue'
import changeGroupName from '../views/changeGroupName.vue'
import inviteFriends from '../views/inviteFriends.vue'
import uploadUserAvatar from '../views/uploadUserAvatar.vue'
import uploadGroupAvatar from '../views/uploadGroupAvatar.vue'
import strangerInformation from '../views/strangerInformation.vue'
import newLogin from '../views/newLogin.vue'
import newEnroll from '../views/newEnroll.vue'
import testYuYin from '../views/testYuYin.vue'
import testShiPin from '../views/testShiPin.vue'
import BigVideo from '../views/BigVideo.vue'
import lookBigImg from '../views/lookBigImg.vue'
import loader from '../views/loader.vue'
const routes = [
  {
    path: '/',
    redirect: '/chatList'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/loader',
    name: 'loader',
    component: loader
  },  
  {
    path:'/testEcharts',
    name:'testEcharts',
    component: testEcharts
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat
  },
  {
    path: '/WechatLogin',
    name: 'WechatLogin',
    component: WechatLogin
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: enroll
  },
  {
    path: '/addFriend',
    name: 'addFriend',
    component: addFriend
  },
  {
    path: '/testWebsocket',
    name: 'testWebsocket',
    component: testWebsocket
  },
  {
    path: '/myMain',
    name: 'myMain',
    component: myMain
  },
  {
    path: '/setting',
    name: 'setting',
    component: setting
  },
  {
    path: '/changeUserName',
    name: 'changeUserName',
    component: changeUserName
  },
  {
    path: '/friendInformation',
    name: 'friendInformation',
    component: friendInformation
  },
  {
    path: '/settingRemark',
    name: 'settingRemark',
    component: settingRemark
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword
  },
  {
    path: '/newFriend',
    name: 'newFriend',
    component: newFriend
  },
  {
    path: '/ISendApplicatFriend',
    name: 'ISendApplicatFriend',
    component: ISendApplicatFriend
  },
  {
    path: '/chatSetting',
    name: 'chatSetting',
    component: chatSetting
  },
  {
    path: '/publishDynamic',
    name: 'publishDynamic',
    component: publishDynamic
  },
  {
    path: '/publishDynamicOnlyText',
    name: 'publishDynamicOnlyText',
    component: publishDynamicOnlyText
  },
  {
    path: '/dynamicDetails',
    name: 'dynamicDetails',
    component: dynamicDetails
  },
  {
    path: '/dynamicInteraction',
    name: 'dynamicInteraction',
    component: dynamicInteraction
  },
  {
    path: '/reviewDetails',
    name: 'reviewDetails',
    component: reviewDetails
  },
  {
    path: '/vidio',
    name: 'vidio',
    component: vidio
  },
  {
    path: '/manageChatHistory',
    name: 'manageChatHistory',
    component: manageChatHistory
  },
  {
    path: '/group',
    name: 'group',
    component: group
  },
  {
    path: '/groupChat',
    name: 'groupChat',
    component: groupChat
  },
  {
    path: '/createGroup',
    name: 'createGroup',
    component: createGroup
  },
  {
    path: '/groupInformation',
    name: 'groupInformation',
    component: groupInformation
  },
  {
    path: '/changeGroupName',
    name: 'changeGroupName',
    component: changeGroupName
  },
  {
    path: '/inviteFriends',
    name: 'inviteFriends',
    component: inviteFriends
  },
  {
    path: '/uploadUserAvatar',
    name: 'uploadUserAvatar',
    component: uploadUserAvatar
  },
  {
    path: '/uploadGroupAvatar',
    name: 'uploadGroupAvatar',
    component: uploadGroupAvatar
  },
  {
    path: '/strangerInformation',
    name: 'strangerInformation',
    component: strangerInformation
  },
  {
    path: '/newLogin',
    name: 'newLogin',
    component: newLogin
  },
  {
    path: '/newEnroll',
    name: 'newEnroll',
    component: newEnroll
  },
  {
    path: '/testYuYin',
    name: 'testYuYin',
    component: testYuYin
  },
  {
    path: '/testShiPin',
    name: 'testShiPin',
    component: testShiPin
  },
  {
    path: '/BigVideo',
    name: 'BigVideo',
    component: BigVideo
  },
  {
    path: '/lookBigImg',
    name: 'lookBigImg',
    component: lookBigImg
  },
  {
    path: '/navigation',
    name: 'navigation',
    component: Navigation,
    children:[
      {
        path: '/myView',
        name: 'myView',
        component: myView,
      },
      {
        path: '/homeview',
        name: 'homeview',
        component: homeView,
      },
      {
        path: '/dynamicSpace',
        name: 'dynamicSpace',
        component: dynamicSpace,
      },
      {
        path: '/chatList',
        name: 'chatList',
        component: chatList,
      },
      {
        path: '/txl',
        name: 'txl',
        component: txl,
      },
      {
        path: '/groupChatList',
        name: 'groupChatList',
        component: groupChatList,
      },
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to,from,next)=>{
    if(to.path=='/newLogin' || to.path === '/newEnroll' || to.path === '/loader'){
      return next();
    }
    const tokenStr = window.sessionStorage.getItem("token");
    if(!tokenStr){
      //window.alert("请先登录！！！");
      //没有凭证
      return next('/loader');
    }
    next();
  });



export default router;
