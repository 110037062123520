<template>
    <div class="dynamicSpaceBody">
        <div class="top">
            <p>动态空间</p>
            <img @click="toInteraction" style="width: 25px; height: 25px; position: absolute; right: 50px;"
                src="../assets/通知.png">
            <span v-if="this.read0Sum > 0">{{ this.read0Sum }}</span>
            <img @click="jiaFun" style="width: 25px; height: 25px; position: absolute; right: 15px;"
                src="../assets/圆加号.png">
        </div>
        <div v-if="selectVis" class="selectVis">
            <p @click="toPublishDyanmic">文字图片动态</p>
            <p @click="toPublishDynamicOnlyText">单纯文字动态</p>
            <el-button @click="closeSelectVis" size="small" type="info">取消</el-button>
        </div>
        <!-- 大图片 -->
        <div class="bigImgFu" @click="closeBigImg">
            <img v-if="bigImgSrc" class="bigImg" :src="bigImgSrc">
        </div>
        <div class="neirong">
            <div>
                <div class="card" v-for="d in this.dynamicList">
                    <div @click="toDynamicDetails(d)">
                        <!-- 头部信息 -->
                        <div class="card_top">
                            <img :src="d.avatar">
                            <p class="p1">{{ d.nickName }}</p>
                            <p class="p2">{{ d.publishTime }}</p>
                        </div>
                        <!-- 动态文字部分 -->
                        <div class="card_text">
                            <p>{{ d.content }}</p>
                        </div>
                    </div>

                    <!-- 动态图片部分 -->
                    <div class="card_img">
                        <img :src="img" v-for="img in JSON.parse(d.imageList)" @click="getBigImgSrc(img)">
                    </div>
                    <!-- 好友互动部分 -->
                    <div class="card_hudong">
                        <div style="display: flex;align-items: center;">
                            <img src="../assets/评论2.png" alt="">
                            <p>{{ d.commentSum }}</p>
                        </div>
                        <div style="display: flex;align-items: center;">
                            <img @click="handLike(d)" v-if="d.dzzt == 0" src="../assets/未点赞.png">
                            <img @click="cancelLike(d)" v-if="d.dzzt == 1" src="../assets/已点赞.png">
                            <p>{{ d.likeSum }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
// import SockJS from 'sockjs-client/dist/sockjs.min.js';
// import Stomp from 'stompjs';
export default {
    data() {
        return {
            dynamicList: [],  //动态列表
            myId: null,
            selectVis: false,
            src: require('../assets/未点赞.png'),
            bigImgSrc: null,
            readView0Count: null, //未读评论数
            readLike0Count: null, //未读点赞数
            stompClient: null,
            read0Sum: null, //未读消息总数
            ScrollPoint: null
        };
    },
    methods: {
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        //查询我及我的好友的动态
        getMyAndFriendDynamic() {
            const params = new URLSearchParams;
            params.append("myId", this.myId);
            this.$axios.post("/Dynamic/getMyAndFriendDynamic", params).then((res) => {
                if (res.data.status == 200) {
                    // console.log(res);
                    this.dynamicList = res.data.data;
                } else {
                    console.log("动态获取失败！");
                }
            })
        },
        //点赞功能
        handLike(d) {
            var body = {
                handerUserId: this.myId,
                dynamicId: d.id
            }
            this.$axios.post("/Dynamic/handLike", body).then((res) => {
                // console.log(res.data.data);
                this.getMyAndFriendDynamic();
            })
        },
        //取消点赞
        cancelLike(d) {
            var body = {
                handerUserId: this.myId,
                dynamicId: d.id
            }
            this.$axios.post("/Dynamic/cancelLike", body).then((res) => {
                // console.log(res.data.data);
                this.getMyAndFriendDynamic();
            })
        },
        //打开selectVis
        openSelectVis() {
            this.selectVis = true;
        },
        //关闭selectVis
        closeSelectVis() {
            this.selectVis = false;
        },
        //点击加号触发的事件
        jiaFun() {
            if (this.selectVis == false) {
                this.openSelectVis();
            } else {
                this.closeSelectVis();
            }
        },
        toPublishDyanmic() {
            this.$router.push('/publishDynamic');
        },
        toPublishDynamicOnlyText() {
            this.$router.push('/publishDynamicOnlyText');
        },
        //查看动态详情
        toDynamicDetails(d) {
            this.setScrollPoint();
            localStorage.setItem('dynamicId', d.id);
            localStorage.setItem('DynamicUserId', d.userId);
            this.$router.push('/dynamicDetails');
        },
        //点击图片查看大图（获取到相应的链接）
        getBigImgSrc(img) {
            this.setScrollPoint();
            localStorage.setItem('imgPath', img);
            this.$router.push('/lookBigImg');
        },
        //点击大图关闭预览
        closeBigImg() {
            this.bigImgSrc = null;
        },
        //查看互动消息
        toInteraction() {
            this.$router.push('/dynamicInteraction');
        },
        // getReadView0Count(){
        //     const params = new URLSearchParams;
        //     params.append('userId',this.myId);
        //     this.$axios.post("/Dynamic/getReadView0",params).then((res)=>{
        //         this.readView0Count = res.data.data;
        //         //console.log("获取未读评论数");
        //     })
        // },
        // getReadLike0Count(){
        //     const params = new URLSearchParams;
        //     params.append('userId',this.myId);
        //     this.$axios.post("/Dynamic/getReadLike0",params).then((res)=>{
        //         this.readLike0Count = res.data.data;
        //         //console.log("获取未读消息数");
        //     })
        // },
        //获取未读消息总数
        getRead0Sum() {
            const params = new URLSearchParams;
            params.append('userId', this.myId);
            this.$axios.post("/Dynamic/getAboveMeRead0Sum", params).then((res) => {
                this.read0Sum = res.data.data;
                console.log("未读消息总数：" + this.read0Sum);
            })
        },
        //stomp的建立部分：
        // connectWebSocket() {
        //     const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
        //     this.stompClient = Stomp.over(socket);
        //     this.stompClient.connect({}, this.onConnected, this.onError);
        // },
        // onConnected() {
        //     console.log('WebSocket连接已建立');
        //     // 在连接成功后，可以订阅目标主题
        //     this.stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);  //订阅我的专属地址
        // },
        // onError(error) {
        //     console.error('WebSocket连接错误:', error);
        // },
        // onMessageReceived(message) {
        //     console.log('收到消息:', message.body);
        //     // 处理接收到的消息
        //     this.getRead0Sum();
        //     this.getMyAndFriendDynamic();
        // },
        onMessageReceived(message) {
            console.log('收到消息:', message.body);
            // 处理接收到的消息
            this.getRead0Sum();
            this.getMyAndFriendDynamic();
        },
        //记录当前滚动条位置
        setScrollPoint() {
            const chatContainer = document.querySelector('.neirong');
            console.log(chatContainer.scrollTop);
            localStorage.setItem('ScrollPoint', chatContainer.scrollTop);
        },
        //使滚动条滚动到记录的位置
        scrollToPoint() {
            if (localStorage.getItem('ScrollPoint')) {
                const chatContainer = document.querySelector('.neirong');
                chatContainer.scrollTop = parseInt(localStorage.getItem('ScrollPoint'), 10); // 滚动到指定位置
                localStorage.removeItem('ScrollPoint');
                this.ScrollPoint = null;
            }
        },
    },

    created() {
        this.getId();
    },
    mounted() {
        this.getMyAndFriendDynamic();
        this.getRead0Sum();
        //订阅WebSocket主题
        const stompClient = inject('stompClient');
        setTimeout(() => {
            if (stompClient) {
                stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);
                console.log("dynamicSpace连接成功");
            };
        }, 500)
    },
    watch: {
        dynamicList() {
            this.$nextTick(() => {
                this.scrollToPoint();
            });

        }
    }
}
</script>

<style scoped>
.dynamicSpaceBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top span {
    background-color: red;
    color: white;
    border-radius: 100px;
    font-size: 13px;
    position: absolute;
    right: 43px;
    top: 10px;
    width: 18px;
    height: 18px;
    text-align: center;
}

.neirong {
    width: 100vw;
    height: calc(100vh - 55px - 4.3rem);
    overflow: auto;
    display: flex;
    justify-content: center;
}

.neirong .card {
    width: 95vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    max-height: 350px;
    margin-bottom: 5px;
    margin-top: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}

.card .card_top {
    display: flex;
    align-items: center;
}

.card .card_top img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-top: 5px;
    border-radius: 8px;
    object-fit: cover;
}

.card .card_top .p1 {
    margin-left: 20px;
    font-size: 17px;
    flex: 1;
}

.card .card_top .p2 {
    color: gray;
    font-size: 12px;
    margin-right: 20px;
}

.card .card_text p {
    margin-left: 15px;
    margin-right: 15px;
    white-space: pre-wrap;
}

.card .card_img {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.card .card_img img {
    width: 32%;
    height: calc(95vw * 0.32);
    border-radius: 5px;
    margin-bottom: 3px;
    object-fit: cover;
}

.card .card_hudong {
    display: flex;
    justify-content: space-evenly;
}

.card .card_hudong img {
    width: 25px;
    height: 25px;
}

.card .card_hudong p {
    margin-left: 5px;
    color: dodgerblue;
}

.selectVis {
    width: 120px;
    background-color: aliceblue;
    text-align: center;
    position: absolute;
    right: 5px;
    border-radius: 10px;
    z-index: 10;
}

.el-button--small {
    --el-button-size: 24px;
    height: var(--el-button-size);
    padding: 5px 11px;
    margin-bottom: 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.bigImg {
    width: 100vw;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    background-color: black;
    height: 100vh;
    object-fit: contain;
}
</style>