<template>
    <div class="MyViewBody">
        <!-- 头像昵称区域 -->
        <div class="div_top">
            <div class="HeadSculpture" @click="toMyMain">
                <div class="avatar">
                    <img :src="userInformation.avatar">
                </div>
                <div class="nickname">
                    <p class="p1">{{ userInformation.userName }}</p>
                    <p class="p2">账号:{{ userInformation.userId }}</p>
                </div>
                <!-- <div class="equit">
                <img style="height: 1rem; width: 1rem;" src="../assets/退出.png" @click="equitLogin">
            </div> -->
                <div class="qr-code">
                    <img
                        src="https://tse2-mm.cn.bing.net/th/id/OIP-C.F7FVy6kLtSmDqE_OZ1CjKAHaHa?w=208&h=208&c=7&r=0&o=5&dpr=1.3&pid=1.7">
                </div>
            </div>
        </div>


        <!-- 导航区域 -->
        <!-- <div class="mena">
            <img src="../assets/朋友圈.png">
            <p>朋友圈</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <div class="mena">
            <img src="../assets/收藏02.png">
            <p>收藏</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <div class="mena">
            <img src="../assets/微信支付.png">
            <p>支付</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <div class="mena">
            <img src="../assets/生活服务.png">
            <p>生活服务</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <div class="mena">
            <img src="../assets/视频号管理.png">
            <p>视频号</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <div class="mena" @click="toSetting">
            <img src="../assets/设置.png">
            <p>设置</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div> -->

        <div class="center">
            <div class="card">
                <div class="part">
                    <img src="../assets/朋友圈.png" alt="">
                    <p>朋友圈</p>
                    <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
                </div>
                <div class="part">
                    <img src="../assets/生活服务.png" alt="">
                    <p>生活服务</p>
                    <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
                </div>
                <div class="part">
                    <img src="../assets/收藏02.png" alt="">
                    <p>收藏</p>
                    <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
                </div>
                <div class="part" @click="toSetting">
                    <img src="../assets/设置.png" alt="">
                    <p>设置</p>
                    <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
                </div>
            </div>
        </div>



    </div>
</template>

<script>
export default {
    data() {
        return {
            userInformation: {
                userId: '',
                userName: '',
                avatar: ''
            }  //登录后对应的账号信息
        }
    },
    methods: {
        //获取所登录账号的信息
        getUserInformation() {
            let LoginerUserId = parseInt(localStorage.getItem('userId'), 10);
            console.log("取到的账号为：" + LoginerUserId);
            this.$axios.get('/User/getUserInformation/' + LoginerUserId).then((res) => {
                //console.log(res);
                this.userInformation.userId = res.data.data[0].userId;
                this.userInformation.userName = res.data.data[0].userName;
                this.userInformation.avatar = res.data.data[0].avatar;
            })
        },
        //退出登录，跳转到登录界面
        equitLogin() {
            this.$router.push('/WechatLogin');
        },
        //点击头像昵称区域，跳转到头像昵称设置界面
        toMyMain() {
            this.$router.push('/myMain')
        },
        //点击设置，跳转到设置界面
        toSetting() {
            this.$router.push('/setting')
        },
    },
    mounted() {
        this.getUserInformation();
    },

}
</script>

<style scoped>
.MyViewBody {
    background-color: #EAEAEA;
    position: absolute;
    width: 100%;
    height: calc(100% - 4.3rem);
    overflow: auto;
    left: 0;
    top: 0;
}

img {
    height: 2rem;
    width: 2rem;
    margin-left: 0.8rem;
    margin-right: 1rem;
    border-radius: 8px;
}

.div_top {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.HeadSculpture p {
    color: black;
    font-size: 1rem;
    margin-left: 5rem;
    position: absolute;
    bottom: 0.5rem;
}

.mena {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    /* 盒子宽度100% */
    height: 4rem;
    justify-content: space-between;
    /* 子元素水平分布 */
    position: relative;
}

hr {
    padding: 0;
    margin: 0;
    color: gray;
    border: none;
    background-color: #ccc;
    height: 1px;
}

.mena+.mena {
    border-top: 1px solid #ccc;

}

.HeadSculpture {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    position: relative;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;
    height: 100px;
    width: 80vw;
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%);  /*阴影*/
}

.HeadSculpture .avatar img {
    width: 5.2rem;
    height: 5.2rem;
    object-fit: cover;
}

.HeadSculpture .nickname {
    display: flex;
    text-align: center;
}

.HeadSculpture .nickname .p1 {
    margin: 0;
    font-size: 1.25rem;
    top: 2rem;
    left: 7.5rem;
}

.HeadSculpture .nickname .p2 {
    margin: 0;
    font-size: 0.9rem;
    top: 4rem;
    left: 7.5rem;
    color: gray;
}

.HeadSculpture .nickname div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
}

.HeadSculpture .qr-code img {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 4.2rem;
    right: 0.5rem;
}

.equit {
    position: absolute;
    margin-top: -60px;
    right: 0;
}

.center {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.center .card {
    width: 90vw;
    height: 350px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center .card .part {
    width: 90vw;
    height: 55px;
    background-color: white;
    border-radius: 8px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
}

.center .card .part img {
    width: 35px;
    height: 35px;
}

.center .card .part p {
    flex: 1;
    margin-left: 10px;
}
</style>