<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>视频通话</p>
        </div>
        <div>
            <video class="video1" ref="localVideo" autoplay></video>
            <video class="video2" ref="remoteVideo" autoplay></video>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,
            peerConnection: null,
            friendId: null,
            localStream: null,
            remoteStream: null,
            friendVideoOffer: null
        };
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.friendId = parseInt(localStorage.getItem('userId2'), 10);
            //console.log('friendId:' + this.friendId);
        },
        getOffer() {
            this.friendVideoOffer = localStorage.getItem('offer');
            console.log("我是offer："+this.friendVideoOffer);
        },
        //获取媒体流，使用 getUserMedia 方法获取摄像头和麦克风的媒体流
        async startVideo() {
            try {
                this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                this.$refs.localVideo.srcObject = this.localStream;
                //this.$refs.remoteVideo.srcObject = this.localStream;
                await this.createPeerConnection();
            } catch (error) {
                console.error('Error accessing media devices:', error);
            }
        },
        //创建对等连接
        async createPeerConnection() {
            this.peerConnection = new RTCPeerConnection();

            this.localStream.getTracks().forEach(track => {
                this.peerConnection.addTrack(track, this.localStream);
            });

            this.peerConnection.ontrack = event => {
                this.remoteVideo.srcObject = event.streams[0];
            };

            const offer = await this.peerConnection.createOffer();
            await this.peerConnection.setLocalDescription(offer);

            //用websocket发送offer给朋友
            const params = new URLSearchParams();
            params.append('offer', offer);
            params.append('friendId', this.friendId);
            this.$axios.post("/websocket/sendVideoOffer", params).then((res) => {
                console.log(res);
            })
        },
        //拿到了对方发来的offer后：
        async handleReceivedOffer() {
            if (!this.peerConnection) {
                console.error('Peer connection is not initialized.');
                return;
            }

            // 设置远程描述
            const friendVideoOffer = new RTCSessionDescription(JSON.parse(this.friendVideoOffer));
            await this.peerConnection.setRemoteDescription(friendVideoOffer);

            // 创建应答
            const answer = await this.peerConnection.createAnswer();

            // 设置本地描述
            await this.peerConnection.setLocalDescription(answer);

            // 将应答发送给对方
            const params = new URLSearchParams();
            params.append('answer', JSON.stringify(this.peerConnection.localDescription));
            params.append('friendId', this.friendId);
            this.$axios.post("/websocket/sendVideoOffer",params).then((res)=>{
                console.log('66666'+res);
            })

            // 将远程视频流赋值给 remoteStream
            this.remoteStream = new MediaStream();
            this.peerConnection.ontrack = event => {
                this.remoteStream.addTrack(event.track, this.remoteStream);
            };

            // 在 video 元素中显示远程视频流
            this.$refs.remoteVideo.srcObject = this.remoteStream;
        }
    },
    mounted() {
        this.getId();
        this.getOffer();
        this.createPeerConnection();
        this.startVideo();
        this.handleReceivedOffer();
    },

}
</script>

<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.video1 {
    width: 100vw;
}

.video2 {
    width: 30vw;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 60px;
}
</style>