<template>
    <div class="chatSettingBody">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>聊天设置</p>
        </div>
        <div class="friend" @click="toFriendInformation">
            <img class="img1" :src="this.friendInformation.avatar">
            <p>{{ this.nickName }}</p>
            <img class="img2" src="../assets/右箭头.png">
        </div>
        <div class="biaoqian" @click="toManageChatHistory">
            <p style="color: black;">管理聊天记录</p>
        </div>
        <div class="biaoqian" @click="showDeleteMessageVis">
            <p>删除聊天记录</p>
        </div>
        <div class="biaoqian" @click="showDeleteFriendVis">
            <p>删除好友</p>
        </div>
        <div class="sureVisFu" v-if="deleteMessageVis">
            <div class="sureVis">
                <p>确定删除与该好友的聊天记录吗？</p>
                <div class="button_div">
                    <el-button @click="closeDeleteMessageVis">取消</el-button>
                    <el-button @click="deleteChatMessage" type="primary">确定</el-button>
                </div>
            </div>
        </div>
        <div class="sureVisFu" v-if="deleteFriendVis">
            <div class="sureVis">
                <p>确定删除该好友吗？同时会彻底删除与该好友的聊天记录，不可撤回！</p>
                <div class="button_div">
                    <el-button @click="closeDeleteFriendVis">取消</el-button>
                    <el-button @click="deleteFriend" type="primary">确定</el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            myId: null,   //我的Id
            friendId: null, //该朋友的Id
            friendInformation: {
                userName: null,
                avatar: null
            },
            nickName: null, //对该朋友的备注
            deleteMessageVis : false,
            deleteFriendVis : false
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //获取Id
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.friendId = parseInt(localStorage.getItem('userId2'), 10);
            //console.log("该朋友的Id为："+this.friendId);
        },
        //通过朋友的Id查询朋友的昵称和头像
        getFriendInformation() {
            this.$axios.get('/User/getUserInformation' + '/' + this.friendId).then((res) => {
                console.log(res);
                this.friendInformation.userName = res.data.data[0].userName;
                this.friendInformation.avatar = res.data.data[0].avatar;
            })
        },
        //通过我的id和朋友id获取备注
        getNickName() {
            this.$axios.get('/FriendShip/getNickNameByUserId1andUserId2/' + this.myId + "/" + this.friendId).then((res) => {
                //console.log(res);
                this.nickName = res.data.data[0].nickName;
            })
        },
        //去好友信息界面
        toFriendInformation() {
            this.$router.push('/friendInformation');
        },
        //展开确认删除聊天信息对话框
        showDeleteMessageVis(){
            this.deleteMessageVis = true;
        },
        //关闭确认删除聊天信息对话框
        closeDeleteMessageVis(){
            this.deleteMessageVis = false;
        },
        //删除聊天信息
        deleteChatMessage() {
            var body = {
                myId: this.myId,
                friendId: this.friendId
            }
            this.$axios.post("/Message/deleteChatMessage", body).then((res) => {
                if (res.data.status == 200) {
                    this.$message.success("聊天记录删除成功！");
                } else {
                    this.$message.error("聊天记录删除失败！");
                }
                this.deleteMessageVis = false;
            })
        },
        //删除好友的方法（此方法同时会删除数据库中二者的聊天记录，不可撤回）
        deleteFriend(){
            var body = {
                myId: this.myId,
                friendId: this.friendId
            }
            this.$axios.post("/FriendShip/deleteAllMessageById",body).then((res)=>{
                if(res.data.status == 200){
                    this.$message.success("好友删除成功！");
                    this.deleteFriendVis = false;
                    this.$router.push("/chatList");
                }else{
                    this.$message.error("好友删除失败！");
                }
            })
        },
        //展开确认删除好友对话框
        showDeleteFriendVis(){
            this.deleteFriendVis = true;
        },
        //关闭确认删除好友对话框
        closeDeleteFriendVis(){
            this.deleteFriendVis = false;
        },
        //跳转到管理聊天记录
        toManageChatHistory(){
            this.$router.push('/manageChatHistory');
        }
    },
    created() {
        this.getId();
        this.getFriendInformation();
        this.getNickName();
    }
}
</script>

<style scoped>
.chatSettingBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 3px;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.friend {
    background-color: white;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.friend .img1 {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    border-radius: 7px;
}

.friend .img2 {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.friend p {
    flex: 1;
    margin-left: 10px;
}

.biaoqian {
    background-color: white;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 5px;
}

.biaoqian p {
    margin-left: 10px;
    color: red;
}
.sureVisFu{
    text-align: center;
    width: 100vw;
    display: flex;
    justify-content: center;
    height: 120px;
    position: absolute;
    top: 45%;
    z-index: 10;
}
.sureVis {
    width: 80%;
    background-color: white;
    border-radius: 20px;
    height: 100%;
}
.sureVisFu .sureVis p{
    margin: 0;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sureVisFu .sureVis .button_div{
    height: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
</style>