<template>
    <div class="body">
        <div class="top">
            <p>消息</p>
            <div>
                <img id="sousuo" style="width: 25px;height: 25px;" src="../assets/搜索01.png" alt="">
                <img id="add" style="width: 25px;height: 25px;" src="../assets/圆加号.png" @click="toAddFriend">
            </div>
        </div>
        <div class="ContentFu">
            <div @click="toChatView(message)" class="ContactList" v-for="message in MessagesList">
                <img :src="message.avatar">
                <span v-if="message.senderId != this.myId && message.isRead === 0" class="jiaobiao">亟</span>
                <span class="beizhu">{{ message.nickName }}</span>
                <span class="message">{{ message.content }}</span>
                <span v-if="message.imagePath" class="message">[图片]</span>
                <span v-if="message.wavPath" class="message">[语音]</span>
                <span v-if="message.videoPath" class="message">[视频]</span>
                <span class="finallyMessageTime">{{ message.sendTime }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
// import SockJS from 'sockjs-client/dist/sockjs.min.js';
// import Stomp from 'stompjs';
export default {
    data() {
        return {
            FriendShipList: [],   //好友列表
            MessagesList: [],  //消息列表
            myId: null,   //我的Id
            // FriendToMeIsRead0Count: null
        }
    },

    methods: {
        //获取myId
        getMyId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        //点击跳转到聊天页
        toChatView(message) {
            let userId2 = message.userId2;
            console.log("获取到的好友账号为" + userId2);
            localStorage.setItem("userId2", userId2); // 将userId2存储在本地
            this.$router.push('/chat');
        },

        //获取消息列表
        getMessagesList() {
            let userId = parseInt(localStorage.getItem('userId'), 10);
            console.log('userId=' + userId);
            this.$axios.get("/Message/getChatListByUserId/" + userId).then((res) => {
                console.log(res);
                this.MessagesList = res.data.data;
            })
        },
        //点击加号跳转到添加朋友的界面
        toAddFriend() {
            this.$router.push('/addFriend');
        },

        //查询好友发给我的消息中有多少条是未读的 senderId:朋友Id receiverId： 我的Id
        // getFriendToMeIsRead0Count(message) {
        //     var FriendToMeIsRead0CountBody = {
        //         senderId: message.userId2,
        //         receiverId: this.myId
        //     }
        //     this.$axios.post("/Message/FriendToMeIsRead0Count", FriendToMeIsRead0CountBody).then((res) => {
        //         this.FriendToMeIsRead0Count = res.data.data;
        //         console.log(this.FriendToMeIsRead0Count);
        //     })
        // },

        //stomp的建立部分：
        // connectWebSocket() {
        //     const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
        //     this.stompClient = Stomp.over(socket);
        //     this.stompClient.connect({}, this.onConnected, this.onError);
        // },
        // onConnected() {
        //     console.log('WebSocket连接已建立');
        //     // 在连接成功后，可以订阅目标主题
        //     this.stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);  //订阅我的专属地址
        // },
        // onError(error) {
        //     console.error('WebSocket连接错误:', error);
        // },
        // onMessageReceived(message) {
        //     console.log('收到消息:', message.body);
        //     // 处理接收到的消息
        //     this.getMessagesList();//重新获取消息列表
        // },
        onMessageReceived(message) {
            console.log('收到消息:', message.body);
            // 处理接收到的消息
            this.getMessagesList();
        }

    },
    //钩子函数
    mounted() {
        //this.getFriendListByUserId1();
        this.getMessagesList();
        //订阅WebSocket主题
        const stompClient = inject('stompClient');
        setTimeout(() => {
            if (stompClient) {
                stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);
                console.log("chatList连接成功");
            };
        }, 500)
    },
    created() {
        this.getMyId();
        //this.connectWebSocket();

    },
}
</script>

<style scoped>
.body {
    background-color: #EAEAEA;
}

.ContactList {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    position: relative;
}

/* .ContactList+.ContactList {
    border-top: 1px solid #ccc;
} */

.ContactList img {
    height: 3rem;
    width: 3rem;
    margin-left: 0.8rem;
    margin-right: 1rem;
    border-radius: 8px;
}

.ContactList .beizhu {
    margin-top: -20px;
    left: 77px;
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ContactList .message {
    color: darkcyan;
    font-size: 13px;
    left: 77px;
    position: absolute;
    bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}

.ContactList .finallyMessageTime {
    position: absolute;
    right: 20px;
    color: darkcyan;
    font-size: 10px;
    top: 15px;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 0;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

#sousuo {
    position: absolute;
    right: 43px;
    top: 15px;
}

#add {
    position: absolute;
    right: 5px;
    top: 15px;
}

.ContentFu {
    height: calc(100vh - 55px - 4.3rem);
    overflow: auto;
    border-radius: 8px;
}

.ContentFu .jiaobiao {
    background-color: red;
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    top: 5px;
    font-size: 12px;
}
</style>