<template>
    <div class="Body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>朋友信息</p>
        </div>
        <!-- 确认框 -->
        <div class="sureVisFu" v-if="deleteFriendVis">
            <div class="sureVis">
                <p>确定删除该好友吗？同时会彻底删除与该好友的聊天记录，不可撤回！</p>
                <div class="button_div">
                    <el-button @click="closeDeleteFriendVis">取消</el-button>
                    <el-button @click="deleteFriend" type="primary">确定</el-button>
                </div>
            </div>
        </div>
        <hr />
        <!-- 好友信息部分 -->
        <div class="center">
            <div class="friendAvatar">
                <img :src="friendInformation.avatar">
            </div>
            <div class="friendInformation">
                <p id="beizhu"><b>{{nickName}}</b></p>
                <p id="nicheng">昵称: {{friendInformation.userName}}</p>
                <p id="wxhao">微信号: {{friendId}}</p>
            </div>
        </div>
        <hr />
        <div class="mena" @click="toSettingRemark">
            <p>设置及修改备注</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <hr />
        <div class="mena">
            <p>朋友权限</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <hr />
        <div class="mena">
            <p>动态空间</p>
            <img style="height: 1rem; width: 1rem;" src="../assets/右箭头.png">
        </div>
        <hr />
        <div class="mena" @click="showDeleteFriendVis">
            <p style="color: red;">删除好友</p>
        </div>
        <hr />
        <div class="sendMessage" @click="toChatView">
            <img src="../assets/消息3.png">
            <p>发消息</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            myId: null,   //我的Id
            friendId: null, //该朋友的Id
            friendInformation: {
                userName: null,
                avatar: null
            },
            nickName: null, //对该朋友的备注
            deleteFriendVis : false
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        //获取Id
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.friendId = parseInt(localStorage.getItem('userId2'), 10);
            //console.log("该朋友的Id为："+this.friendId);
        },
        //通过朋友的Id查询朋友的昵称和头像
        getFriendInformation() {
            this.$axios.get('/User/getUserInformation' + '/' + this.friendId).then((res) => {
                console.log(res);
                this.friendInformation.userName = res.data.data[0].userName;
                this.friendInformation.avatar = res.data.data[0].avatar;
            })
        },
        //通过我的id和朋友id获取备注
        getNickName(){
            this.$axios.get('/FriendShip/getNickNameByUserId1andUserId2/' + this.myId + "/" + this.friendId).then((res) => {
                //console.log(res);
                this.nickName = res.data.data[0].nickName;
            })
        },
        //跳转到聊天页
        toChatView(){
            this.$router.push('/chat');
        },
        //跳转到设置备注页
        toSettingRemark(){
            this.$router.push('/settingRemark');
        },
        //删除好友的方法（此方法同时会删除数据库中二者的聊天记录，不可撤回）
        deleteFriend(){
            var body = {
                myId: this.myId,
                friendId: this.friendId
            }
            this.$axios.post("/FriendShip/deleteAllMessageById",body).then((res)=>{
                if(res.data.status == 200){
                    this.$message.success("好友删除成功！");
                    this.deleteFriendVis = false;
                    this.$router.push("/txl");
                }else{
                    this.$message.error("好友删除失败！");
                }
            })
        },
        //展开确认删除好友对话框
        showDeleteFriendVis(){
            this.deleteFriendVis = true;
        },
        //关闭确认删除好友对话框
        closeDeleteFriendVis(){
            this.deleteFriendVis = false;
        },
    },
    created() {
        this.getId();
    },
    mounted() {
        this.getNickName();
        this.getFriendInformation();
    }
}
</script>

<style scoped>
.Body {
    background-color: gainsboro;
    width: 100vw;
    height: 100vh;
}

.top {
    display: flex;
    background-color: white;
    height: 55px;
    align-items: center;
}

.top img {
    width: 25px;
    height: 25px;
}

.top #back {
    flex: 1;
    margin-left: 10px;
}

.top #caidan {
    margin-right: 10px;
}

.top+.center {
    margin-top: 10px;
}

.center {
    display: flex;
    width: 100%;
    background-color: white;
}

.friendAvatar {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.friendAvatar img {
    width: 7rem;
    height: 7rem;
    border-radius: 10px;
}

.friendInformation {
    width: 60%;
    margin-left: 10px;
}

.friendInformation #beizhu {
    font-size: 25px;
}

.friendInformation #nicheng {
    color: darkslategrey;
}

.friendInformation #wxhao {
    color: darkslategrey;
}

hr {
    height: 0;
    margin: 0;
    padding: 0;
}

.mena {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    /* 盒子宽度100% */
    height: 3rem;
    justify-content: space-between;
    /* 子元素水平分布 */
    position: relative;
}

.mena p {
    margin-left: 10px;
}

.sendMessage {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.sendMessage img {
    width: 40px;
    height: 40px;
}

.sendMessage p {
    font-size: 20px;
}
.sureVisFu{
    text-align: center;
    width: 100vw;
    display: flex;
    justify-content: center;
    height: 120px;
    position: absolute;
    top: 45%;
    z-index: 10;
}
.sureVis {
    width: 80%;
    background-color: white;
    border-radius: 20px;
    height: 100%;
}
.sureVisFu .sureVis p{
    margin: 0;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sureVisFu .sureVis .button_div{
    height: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
</style>