<template>
    <div class="body">
        <div class="top">
            <img @click="goBack" src="../assets/navigate-left.png" alt="">
            <p>{{ this.groupInfformation.groupName }}</p>
            <img @click="toGroupInformation" style="width: 25px; height: 25px; position: absolute; right: 15px;"
                src="../assets/更多.png">
        </div>
        <!-- 展示群聊天信息 -->
        <div :class="{ 'chatCenter': !this.chatCenter2, 'chatCenter2': this.chatCenter2 }">
            <div :class="{ 'xiaoxi': m.senderUserId == this.myId, 'xiaoxi2': m.senderUserId != this.myId }"
                v-for="m in this.GroupMessageAndUserInformation">
                <div class="div1"></div>
                <div class="message">
                    <div class="glName">
                        <div class="div2"></div>
                        <span class="name">{{ m.userName }}</span>
                    </div>
                    <p v-if="m.imagePath == null" class="content">{{ m.content }}</p>
                    <img @click="getBigImgSrc(m)" v-if="m.imagePath != null" :src="m.imagePath" style="width: 50vw;">
                </div>
                <img class="avatar" :src="m.avatar" @click="toSeeGroupUserInformation(m.senderUserId)">
            </div>
        </div>
        <!-- 底部消息发送框 -->
        <div :class="{ 'chat-input': !isChatInput2, 'chat-input2': isChatInput2 }">
            <div class="file-input-container" @click="toggleMenu">
                <input type="file" style="display: none;" accept="image/*" ref="fileInput" @change="onFileSelected">
                <img class="plus-icon" style="width: 30px; height: 30px; margin-right: 5px;" src="../assets/加号3.png">
            </div>
            <el-input @click="handInput" type="textarea" :rows="1" v-model="newMessage" placeholder="输入消息..."/>
            <button @click="sendMessage">发送</button>
        </div>

        <div :class="{ 'caidan': !isCaidan2, 'caidan2': isCaidan2 }">
            <img @click="selectFile" style="height: 40px;" src="../assets/相册.png">
            <img style="height: 40px;" src="../assets/文件.png" alt="">
            <img @click="toVidio" style="height: 40px;" src="../assets/视频通话.png">
        </div>

        <!-- 大图片 -->
        <div class="bigImgFu" @click="closeBigImg">
            <img v-if="bigImgSrc" class="bigImg" :src="bigImgSrc">
        </div>
    </div>


</template>

<script>
import { inject } from 'vue';
export default {
    data() {
        return {
            myId: null,
            groupId: null,
            groupInfformation: {
                groupName: null
            },
            GroupMessageAndUserInformation: [], //群消息及发言人信息
            isChatInput2: false,
            isCaidan2: false,
            newMessage: null,
            isScrollAtBottom: true, // 是否滚动到底部
            chatCenter2: false,
            selectedFile: null, //设置一个变量来存储选择的文件
            bigImgSrc: null
        }
    },
    methods: {
        goBack() {
            // 处理返回逻辑
            this.$router.back();
        },
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
            this.groupId = parseInt(localStorage.getItem(('groupId'), 10));
        },
        //判断该用户是否属于该群，如果不属于，当做拦截
        InSureUserInGroup() {
            var body = {
                userId: this.myId,
                groupId: this.groupId
            }
            this.$axios.post("/GroupUser/InSureUserInGroup", body).then((res) => {
                if (res.data.status == 500) {
                    console.log(res);
                    console.log("不属于该群！！！");
                    this.$router.replace("/groupChatList");
                }
            })

        },
        //按照groupId查询群信息
        getGroupInformationByGroupId() {
            const params = new URLSearchParams;
            params.append('groupId', this.groupId);
            this.$axios.post("/Group/getGroupInformationByGroupId", params).then((res) => {
                //console.log(res);
                this.groupInfformation.groupName = res.data.data[0].groupName;
                //console.log(this.groupInfformation);
            })
        },
        //根据群号查询聊天消息及发言人信息
        getGroupMessageByGroupId() {
            const params = new URLSearchParams;
            params.append('groupId', this.groupId);
            this.$axios.post("/GroupMessage/getGroupMessageByGroupId", params).then((res) => {
                this.GroupMessageAndUserInformation = res.data.data;
                //console.log(this.GroupMessageAndUserInformation);
            })
        },
        //发送消息
        sendMessage() {
            var body = {
                senderUserId: this.myId,
                content: this.newMessage,
                groupId: this.groupId
            }
            this.$axios.post("/GroupMessage/insertGroupMessageContent", body).then((res) => {
                if (res.data.status == 200) {
                    console.log("发送成功");
                    this.newMessage = null;
                    this.getGroupMessageByGroupId();
                }
            })
        },
        //将指定群中关于我的所有消息设置为已读
        setGroupRead1ByMyIdAndGroupId() {
            var body = {
                myId: this.myId,
                groupId: this.groupId
            }
            this.$axios.post("/ReadCardGroupMessage/setGroupRead1ByMyIdAndGroupId", body).then((res) => {
                console.log(res);
                if (res.data.status == 200) {
                    console.log("已标为已读");
                }
            })
        },
        toGroupInformation() {
            this.$router.push('/groupInformation');
        },
        //点击头像去群友信息界面
        toSeeGroupUserInformation(groupUserId) {
            //先判断此人是不是我的好友
            const params1 = new URLSearchParams;
            params1.append('myId', this.myId);
            params1.append('friendId', groupUserId);
            this.$axios.post('User/estimateFriendShipByMyIdAndFriendId', params1).then((res) => {
                //console.log(res);
                if (res.data.status == 2) {
                    //是好友，跳转到好友信息界面
                    localStorage.setItem('userId2', groupUserId);
                    this.$router.push('/friendInformation');
                } else if (groupUserId == this.myId) {
                    //暂时不执行任何操作
                } else {
                    //不是好友，跳转到陌生人信息界面
                    localStorage.setItem('groupUserId', groupUserId);
                    this.$router.push('/strangerInformation');
                }

            })
        },
        toggleMenu() {
            this.isChatInput2 = !this.isChatInput2;
            this.isCaidan2 = !this.isCaidan2;
            this.chatCenter2 = !this.chatCenter2;
        },
        //处理点击“发送图片”按钮的事件
        selectFile() {
            this.$refs.fileInput.click();
        },
        //处理文件选择事件，并将选择的文件存储在selectedFile变量中
        onFileSelected(event) {
            this.selectedFile = event.target.files[0];
            //调用发送图片的方法
            this.sendImage();
        },
        //发送图片的事件
        sendImage() {
            if (!this.selectedFile) {
                // 文件未选择，进行处理与提示
                console.log("先选择");
                return;
            }
            //有压缩
            this.compressImage(this.selectedFile, 0.8).then((compressedBlob) => {
                const form = new FormData();
                form.append('myId', this.myId);
                form.append('groupId', this.groupId);
                form.append('img', compressedBlob);

                this.$axios.post("/GroupMessage/insertGroupMessageImage", form).then((res) => {
                    console.log(res);
                    if (res.data.status == 200) {
                        console.log("发送成功");
                        this.getGroupMessageByGroupId();
                    }
                })
            }).catch((error) => {
                console.error('压缩图片出错：', error);
            });

        },
        //点击图片查看大图（获取到相应的链接）
        getBigImgSrc(m) {
            this.setScrollPoint();
            localStorage.setItem('imgPath', m.imagePath);
            this.$router.push('/lookBigImg');
        },
        //点击大图关闭预览
        closeBigImg() {
            this.bigImgSrc = null;
        },
        //点击输入框触发的事件
        handInput() {
            if (!this.isChatInput2) {
                setTimeout(() => {
                    this.scrollToBottom();
                }, 100);
            }
        },
        //让滚动条到最底部，以便查看最新消息
        handleScroll(event) {
            const chatContainer = event.target;

            // 判断滚动位置是否接近底部
            this.isScrollAtBottom = chatContainer.scrollHeight - chatContainer.scrollTop <= chatContainer.clientHeight;
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const chatContainer = document.querySelector('.chatCenter');
                chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
            });
        },
        //记录当前滚动条位置
        setScrollPoint() {
            const chatContainer = document.querySelector('.chatCenter');
            console.log(chatContainer.scrollTop);
            localStorage.setItem('ScrollPoint', chatContainer.scrollTop);
        },
        //使滚动条滚动到记录的位置
        scrollToPoint() {
            if (localStorage.getItem('ScrollPoint')) {
                const chatContainer = document.querySelector('.chatCenter');
                chatContainer.scrollTop = parseInt(localStorage.getItem('ScrollPoint'), 10); // 滚动到指定位置
                localStorage.removeItem('ScrollPoint');
            } else {
                this.scrollToBottom();
            }
        },
        //stomp的建立部分：
        // connectWebSocket() {
        //     const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
        //     this.stompClient = Stomp.over(socket);
        //     this.stompClient.connect({}, this.onConnected, this.onError);
        // },
        // onConnected() {
        //     console.log('WebSocket连接已建立');
        //     // 在连接成功后，可以订阅目标主题
        //     this.stompClient.subscribe('/topic/messages/' + this.groupId, this.onMessageReceived);  //订阅我的专属地址
        // },
        // onError(error) {
        //     console.error('WebSocket连接错误:', error);
        // },
        // onMessageReceived(message) {
        //     console.log('收到消息:', message.body);
        //     // 处理接收到的消息
        //     this.getGroupMessageByGroupId();
        // },
        onMessageReceived(message) {
            console.log('收到消息:', message.body);
            // 处理接收到的消息
            this.getGroupMessageByGroupId();
        },
        //图片压缩方法
        compressImage(file, quality) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const maxWidth = 800; // 设置最大宽度
                        const maxHeight = 600; // 设置最大高度

                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob((blob) => {
                            resolve(blob);
                        }, 'image/jpeg', quality);
                    };
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });
        },

    },
    mounted() {
        this.getId();
        //this.connectWebSocket();
        this.InSureUserInGroup();
        this.getGroupInformationByGroupId();
        this.getGroupMessageByGroupId();
        //this.scrollToBottom(); // 初始化时滚动到底部
        // 监听滚动事件
        const chatContainer = document.querySelector('.chatCenter');
        chatContainer.addEventListener('scroll', this.handleScroll);
        //订阅WebSocket主题
        const stompClient = inject('stompClient');
        setTimeout(() => {
            if (stompClient) {
                stompClient.subscribe('/topic/messages/' + this.groupId, this.onMessageReceived);
            };
        }, 500)
    },
    watch: {
        GroupMessageAndUserInformation() {
            //this.scrollToBottom();
            this.$nextTick(() => {
                this.scrollToPoint();
            });

        }
    },
    beforeRouteLeave(to, from, next) {    //路由守卫钩子，当退出当前路由之前会执行此函数
        this.setGroupRead1ByMyIdAndGroupId();
        next();
    }
}
</script>


<style scoped>
.BIG {
    position: absolute;
    width: 100vw;
    height: 100vh;
    bottom: 0;
}

.body {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
    display: flex;
    flex-direction: column;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 0;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.chatCenter {
    width: 100vw;
    height: calc(100vh - 103px);
    overflow: auto;
}

.chatCenter2 {
    width: 100vw;
    height: calc(100vh - 200px);
    overflow: auto;
}

/* .xiaoxi+.xiaoxi {
    margin-top: 10px;
} */

.xiaoxi {
    width: 100vw;
    display: flex;
}

.xiaoxi .div1 {
    flex: 1;
}

.xiaoxi .glName {
    display: flex;
}

.xiaoxi .div2 {
    flex: 1;
}

.xiaoxi .message {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.xiaoxi .name {
    display: block;
    font-size: 10px;
    padding: 0 0 5px 0;
    color: gray;
    margin-top: 18px;
}

.xiaoxi .content {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    border-radius: 10px;
    color: white;
    padding: 8px;
    word-break: break-all;
    margin: 0;
    max-width: 55vw;
    display: inline-table;
    white-space: pre-wrap;
}

.xiaoxi .avatar {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 3px;
    margin-left: 3px;
    margin-top: 20px;
}

.xiaoxi+.xiaoxi2 {
    margin-top: 10px;
}

.xiaoxi2+.xiaoxi2 {
    margin-top: 10px;
}

.xiaoxi2 {
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
}

.xiaoxi2 .div1 {
    flex: 1;
}

.xiaoxi2 .message {
    margin-left: 3px;
}

.xiaoxi2 .content {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    border-radius: 10px;
    color: white;
    padding: 8px;
    word-break: break-all;
    margin: 0;
    max-width: 55vw;
    display: inline-flex;
    white-space: pre-wrap;
}

.xiaoxi2 .avatar {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-left: 3px;
    margin-top: 20px;
}

.xiaoxi2 .glName {
    padding: 0 0 5px 0;
    margin-top: 15px;
}

.xiaoxi2 .name {
    font-size: 10px;
    color: gray;

}

.chat-input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
}

.chat-input input {
    flex: 1;
    height: 35px;
    outline: none;
    padding: 0 0 0 10px;
    font-size: 14px;
}

.chat-input button {
    background-color: deepskyblue;
    color: white;
    border: none;
    outline: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.chat-input2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    bottom: 100px;
    height: 45px;
}

.chat-input2 input {
    flex: 1;
    height: 35px;
    outline: none;
    padding: 0 0 0 10px;
    font-size: 14px;
}

.chat-input2 button {
    background-color: deepskyblue;
    color: white;
    border: none;
    outline: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.caidan {
    position: absolute;
    height: 66px;
    bottom: 0;
    display: none;
}

.caidan2 {
    position: absolute;
    height: 66px;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.bigImg {
    width: 100vw;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    background-color: black;
    height: 100vh;
    object-fit: contain;
}
.el-textarea {
    position: relative;
    display: inline-block;
    /* width: 100%; */
    flex: 1;
    vertical-align: bottom;
    font-size: var(--el-font-size-base);
}
</style>