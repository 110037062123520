<template>
    <div class="body">
        <div class="loader"></div>
    </div>
</template>

<script>
export default{
  data(){
    return{

    }
  },
  methods:{
    toNewLogin(){
      this.$router.replace('/newLogin');
    }
  },
  mounted(){
    setTimeout(()=>{
      this.toNewLogin();
    },2000);
  }
}
</script>


<style scoped>

.body{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  white-space: pre;
  font-size: 30px;
  line-height: 1.2em;
  height:1.2em;
  overflow: hidden;
}
.loader:before {
  content:"Loading...\A⌰oading...\A⌰⍜ading...\A⌰⍜⏃ding...\A⌰⍜⏃⎅ing...\A⌰⍜⏃⎅⟟ng...\A⌰⍜⏃⎅⟟⋏g...\A⌰⍜⏃⎅⟟⋏☌...\A⌰⍜⏃⎅⟟⋏☌⟒..\A⌰⍜⏃⎅⟟⋏☌⟒⏁.\A⌰⍜⏃⎅⟟⋏☌⟒⏁⋔"; 
  white-space: pre;
  display: inline-block;
  animation: l39 1s infinite steps(11) alternate;
}

@keyframes l39 {
  100%{transform: translateY(-100%)}
}
</style>