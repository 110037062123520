<template>
    <div class="body">
        <div class="top">
            <p>群消息</p>
            <img @click="toCreateGroup" src="../assets/创建群聊.png">
        </div>
        <div class="groupList">
            <div class="yuansu" v-for="g in this.GroupChatListDTO" @click="toGroupChat(g.groupId)">
                <img :src="g.groupAvatar">
                <span v-if="g.senderUserId != this.myId && g.isRead == 0" class="jiaobiao">亟</span>
                <div style="height: 3.5rem;">
                    <div class="yuansu_top">
                        <span class="groupName">{{ g.groupName }}</span>
                        <span class="date">{{ g.sendTime }}</span>
                    </div>
                    <p v-if="!g.imagePath" class="content">{{ g.userName }}: {{ g.content }}</p>
                    <p v-if="g.imagePath" class="content">{{ g.userName }}: [图片]</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
// import SockJS from 'sockjs-client/dist/sockjs.min.js';
// import Stomp from 'stompjs';
export default {
    data() {
        return {
            myId: null,
            GroupChatListDTO: [],
            groupIdList: [], //放的是所有包含我的群的群Id
            NoReadMessageGroupByGroupId: []
        }
    },
    methods: {
        getId() {
            this.myId = parseInt(localStorage.getItem('userId', 10));
        },
        getGroupChatListDTO() {
            const params = new URLSearchParams;
            params.append('myId', this.myId);
            this.$axios.post("/GroupMessage/getGroupChatListDTO", params).then((res) => {
                this.GroupChatListDTO = res.data.data;
                console.log(this.GroupChatListDTO);
            })
        },
        toGroupChat(groupId) {
            localStorage.setItem('groupId', groupId);
            this.$router.push('/groupChat');
        },
        getgroupIdList() {
            const params = new URLSearchParams;
            params.append('myId', this.myId);
            this.$axios.post("/Group/getIJoinGroupIdByMyId", params).then((res) => {
                this.groupIdList = res.data.data;
                //console.log(this.groupIdList);
            })
        },
        //获取在某个群中我未读的消息数
        getNoReadMessageGroupByGroupId() {
            const params = new URLSearchParams;
            params.append('myId', this.myId);
            this.$axios.post("/ReadCardGroupMessage/getNoReadMessageGroupByGroupId", params).then((res) => {
                this.NoReadMessageGroupByGroupId = res.data.data;
                console.log(this.NoReadMessageGroupByGroupId);
            })
        },
        toCreateGroup() {
            this.$router.push('/createGroup');
        },
        //stomp的建立部分：
        // connectWebSocket() {
        //     const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
        //     this.stompClient = Stomp.over(socket);
        //     this.stompClient.connect({}, this.onConnected, this.onError);
        // },
        // onConnected() {
        //     console.log('WebSocket连接已建立');
        //     // 在连接成功后，可以订阅目标主题
        //     this.groupIdList.forEach(element => {
        //         //console.log(element);
        //         this.stompClient.subscribe('/topic/messages/' + element, this.onMessageReceived);  //订阅我的专属地址
        //     });
        // },
        // onError(error) {
        //     console.error('WebSocket连接错误:', error);
        // },
        // onMessageReceived(message) {
        //     console.log('收到消息:', message.body);
        //     // 处理接收到的消息
        //     this.getGroupChatListDTO();
        // },

        onMessageReceived(message) {
            console.log('收到消息:', message.body);
            // 处理接收到的消息
            this.getGroupChatListDTO();
        }

    },
    mounted() {
        this.getId();
        //this.connectWebSocket();
        this.getGroupChatListDTO();
        this.getgroupIdList();
        this.getNoReadMessageGroupByGroupId();

        //订阅WebSocket主题
        const stompClient = inject('stompClient');
        setTimeout(() => {
            if (stompClient) {
                for (const element of this.groupIdList) {
                    console.log(element);
                    stompClient.subscribe('/topic/messages/' + element, this.onMessageReceived);
                }
                console.log("groupChatList连接成功");
            };
        }, 500)
    }
}
</script>

<style scoped>
.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 0;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.top img {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 12px;
}

.groupList {
    height: calc(100vh - 55px - 4.3rem);
    width: 100vw;
    overflow: auto;
    border-radius: 8px;
}

/* .yuansu+.yuansu {
    margin-top: 0.1rem;
} */

.yuansu {
    display: flex;
    background-color: white;
    height: 3.5rem;
    align-items: center;
}

.yuansu img {
    width: 3rem;
    height: 3rem;
    border-radius: 8px;
    margin-left: 3px;
}

.yuansu .jiaobiao {
    margin-left: -15px;
    margin-top: -38px;
    background-color: red;
    border-radius: 50px;
    color: white;
    font-size: 12px;
    padding: 2px;
}

.yuansu_top {
    display: flex;
    width: calc(100vw - 60px);
}

.yuansu_top .groupName {
    flex: 1;
    margin-left: 10px;
    margin-top: 4px;
}

.yuansu_top .date {
    margin-right: 10px;
    font-size: 10px;
    color: gray;
    margin-top: 3px;
}

.yuansu .content {
    margin-left: 10px;
    font-size: 13px;
    color: gray;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60vw;
}
</style>