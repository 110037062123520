<template>
    <div class="txlBody">
        <div class="top">
            <p>通讯录</p>
        </div>

        <div class="ContactFu">
            <div style="margin-bottom: 10px;box-shadow: 0 5px 5px 0 rgb(0 0 0 / 10%);">
                <div class="biaoqian" @click="toNewFriend">
                    <div class="img_div"><img src="../assets/新朋友.png" alt=""></div>
                    <p>新的朋友</p>
                    <div class="jiaobiao" v-if="this.ApplicatFriendToMeStatus0Count > 0">
                        {{ this.ApplicatFriendToMeStatus0Count }}</div>
                </div>
                <div class="biaoqian" @click="toISendApplicatFriend">
                    <div class="img_div"><img src="../assets/加好友2.png" alt=""></div>
                    <p>我发出的好友申请</p>
                </div>
                <div class="biaoqian" @click="toGroup">
                    <div class="img_div"><img src="../assets/群聊.png" alt=""></div>
                    <p>群聊</p>
                </div>
            </div>
            <div @click="tofriendInformationView(friend)" class="ContactList" v-for="friend in FriendShipList">
                <img :src="friend.avatar">
                <span class="beizhu">{{ friend.nickName }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
// import SockJS from 'sockjs-client/dist/sockjs.min.js';
// import Stomp from 'stompjs';
export default {
    data() {
        return {
            myId: null,
            FriendShipList: [],   //好友列表
            ApplicatFriendToMeStatus0Count: null, //待处理的好友申请数
        }
    },
    methods: {
        getId() {
            this.myId = parseInt(localStorage.getItem('userId'), 10);
        },
        //点击跳转到朋友信息页
        tofriendInformationView(friend) {
            let userId2 = friend.userId2;
            console.log("获取到的好友账号为" + userId2);
            localStorage.setItem("userId2", userId2); // 将userId2存储在本地
            this.$router.push('/friendInformation');
        },
        //点击跳转到群聊页
        toGroup() {
            this.$router.push('/group');
        },
        //获取好友信息
        getFriendListByUserId1() {
            let userId1 = parseInt(localStorage.getItem('userId'), 10);
            this.$axios.get('/FriendShip/getFriendListByUserId1/' + userId1).then((res) => {
                console.log(res);
                this.FriendShipList = res.data.data;
            })
        },
        //去新朋友界面
        toNewFriend() {
            this.$router.push('/newFriend');
        },
        //去我发出的好友申请界面
        toISendApplicatFriend() {
            this.$router.push('/ISendApplicatFriend');
        },
        //查询我收到的好友申请中是否有待处理
        getApplicatFriendToMeStatus0Count() {
            this.$axios.get("/ApplicatFriend/getApplicatFriendToMeStatus0Count/" + this.myId).then((res) => {
                this.ApplicatFriendToMeStatus0Count = res.data.data;
                console.log(this.ApplicatFriendToMeStatus0Count);
            })
        },
        //stomp的建立部分：
        // connectWebSocket() {
        //     const socket = new SockJS('http://localhost:8080/jiuxing/websocket'); // 修改为相应的服务器地址和端点
        //     this.stompClient = Stomp.over(socket);
        //     this.stompClient.connect({}, this.onConnected, this.onError);
        // },
        // onConnected() {
        //     console.log('WebSocket连接已建立');
        //     // 在连接成功后，可以订阅目标主题
        //     this.stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);  //订阅我的专属地址
        // },
        // onError(error) {
        //     console.error('WebSocket连接错误:', error);
        // },
        // onMessageReceived(message) {
        //     console.log('收到消息:', message.body);
        //     // 处理接收到的消息
        //     this.getApplicatFriendToMeStatus0Count();
        // },
        onMessageReceived(message) {
            console.log('收到消息:', message.body);
            // 处理接收到的消息
            this.getApplicatFriendToMeStatus0Count();
        }
    },
    //钩子函数
    mounted() {
        this.getId();
        this.getFriendListByUserId1();
        this.getApplicatFriendToMeStatus0Count();
        //this.connectWebSocket();

        //订阅WebSocket主题
        const stompClient = inject('stompClient');
        setTimeout(() => {
            if (stompClient) {
                stompClient.subscribe('/topic/messages/' + this.myId, this.onMessageReceived);
                console.log("txl连接成功");
            };
        }, 500)
    }
}
</script>

<style scoped>
.txlBody {
    width: 100vw;
    height: 100vh;
    background-color: #EAEAEA;
}

.top {
    background: linear-gradient(to right, #7ec0ee, #00bfff);
    display: flex;
    align-items: center;
    height: 55px;
    margin-bottom: 0;
}

.top p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: white;
}

.biaoqian {
    display: flex;
    align-items: center;
    background-color: white;
}

/* .biaoqian+.biaoqian{
    margin-top: 1px;
} */

.biaoqian .img_div {
    margin-left: 15px;
}

.biaoqian img {
    width: 2rem;
    height: 2rem;
}

.biaoqian p {
    margin-left: 15px;
    font-size: 1rem;
    flex: 1;
}

.biaoqian .jiaobiao {
    background-color: red;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-right: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ContactFu {
    height: calc(100vh - 55px - 4.3rem);
    overflow: auto;
    border-radius: 8px;
}

.ContactList {
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    position: relative;
}

/* .ContactList+.ContactList {
    margin-top: 0.1rem;
} */

.ContactList img {
    height: 3rem;
    width: 3rem;
    margin-left: 0.8rem;
    margin-right: 1rem;
    border-radius: 7px;
}

.ContactList .beizhu {
    margin-top: 0px;
}

.ContactList .message {
    color: darkcyan;
    font-size: 15px;
    left: 76px;
    position: absolute;
    bottom: 6px;
}

.ContactList .finallyMessageTime {
    position: absolute;
    right: 20px;
    color: darkcyan;
}
</style>